import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { USER_API_BASE_URL } from '../utils/config';

class UserService {
    static async login(email, password) {
        try {
            const response = await axios.post(`${USER_API_BASE_URL}/auth/login`, { email, password });
            const { token, refresh_token } = response.data;
            if (token && refresh_token) {
                localStorage.setItem('token', token);
                localStorage.setItem('refresh_token', refresh_token);
    
                const decodedToken = jwtDecode(token);
                const expirationTime = decodedToken.exp * 1000 - 60000; // 1 minute before actual expiration
                localStorage.setItem('tokenExpirationTime', expirationTime);
    
                this.scheduleTokenExpiration(expirationTime);
            }
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    

    static async register(name, email, password) {
        try {
            const response = await axios.post(`${USER_API_BASE_URL}/auth/register`, { name, email, password });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async createUser(data) {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${USER_API_BASE_URL}/users`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async updateUser(userId, data) {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`${USER_API_BASE_URL}/users/${userId}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getAllUser() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${USER_API_BASE_URL}/users`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getUserById(userId) {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${USER_API_BASE_URL}/users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async deleteUser(userId) {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.delete(`${USER_API_BASE_URL}/users/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getUserByIsApproved() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${USER_API_BASE_URL}/users/pending`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async approveUser(userId) {
        const token = localStorage.getItem('token');
        console.log("token", token);
    
        try {
            const response = await axios.put(
                `${USER_API_BASE_URL}/users/${userId}/approve`, 
                null,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    
    static refreshSession() {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
            // Refresh the session by extending the token expiration time
            const newExpirationTime = Date.now() + (15 * 60 * 1000); // Extend by 15 minutes
            localStorage.setItem('tokenExpirationTime', newExpirationTime);
            this.scheduleTokenExpiration(newExpirationTime);
        }
    }

    static scheduleTokenExpiration(expirationTime) {
        const currentTime = Date.now();
        const timeout = expirationTime - currentTime;

        setTimeout(() => {
            this.handleTokenExpiration();
        }, timeout);
    }

    static handleTokenExpiration() {
        this.logout();
        window.location.href = '/'; // Redirect to login page
    }

    static getTokenExpirationTime() {
        const expirationTime = localStorage.getItem('tokenExpirationTime');
        return expirationTime ? parseInt(expirationTime, 10) : 0;
    }

    static logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('tokenExpirationTime');
    }
    
}

export default UserService;
