import { useEffect, useRef } from "react";
import { FaPaperPlane, FaPaperclip, FaLevelDownAlt } from "react-icons/fa";
import { MdKeyboardCommandKey } from "react-icons/md";

const ChatInput = ({ message, setMessage, onSend, onFileChange }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  return (
    <div className="flex flex-col p-4 m-3 border-1 border-solid border-[#ddd] rounded-lg">
      <textarea
        ref={textareaRef}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your question..."
        className="w-full h-24 p-3 mb-2 rounded-lg resize-none focus:outline-none"
        onKeyDown={(e) => {
          if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
            e.preventDefault();
            onSend();
          }
        }}
      />
      <div className="flex justify-between items-center">
        <label
          htmlFor="file-upload"
          className="cursor-pointer flex text-sm items-center text-gray-600 hover:text-gray-800 transition-colors duration-200"
        >
          <FaPaperclip className="mr-2" />
          <span>Attach file</span>
        </label>
        <input
          id="file-upload"
          type="file"
          accept=".txt,.doc,.docx"
          onChange={onFileChange}
          className="hidden"
        />
        <div className="flex items-center space-x-2">
          <span className="text-xs text-gray-500 mt-1 flex items-center">
            <MdKeyboardCommandKey className="mr-1" />+
            <FaLevelDownAlt className="mx-1 transform rotate-90" />
          </span>
          <button
            className="flex items-center bg-[#007bff] text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors text-sm duration-200"
            onClick={onSend}
          >
            <FaPaperPlane className="mr-2" />
            <span>Send</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
