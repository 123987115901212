import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { USER_API_BASE_URL } from '../utils/config';

class RoleService {
    static async createRole(data) {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(`${USER_API_BASE_URL}/roles`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async updateRole(roleId, data) {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`${USER_API_BASE_URL}/roles/${roleId}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getAllRole() {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${USER_API_BASE_URL}/roles`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    static async getRoleById(roleId) {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${USER_API_BASE_URL}/roles/${roleId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}

export default RoleService;
