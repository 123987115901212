const ConfidenceIndicator = ({ score }) => {
  const getPosition = (score) => {
    switch (score) {
      case "Low":
        return "16.67%";
      case "Medium":
        return "50%";
      case "High":
        return "83.33%";
      default:
        return "50%";
    }
  };

  return (
    <div className="w-24 h-2 bg-gray-200 rounded-full relative">
      <div className="absolute top-0 left-0 h-full w-1/3 rounded-l-full bg-red-600"></div>
      <div className="absolute top-0 left-1/3 h-full w-1/3 bg-yellow-400"></div>
      <div className="absolute top-0 left-2/3 h-full w-1/3 rounded-r-full bg-green-600"></div>
      <div
        className="absolute top-1/2 transform -translate-y-1/2 h-4 w-4 rounded-full bg-white border-1 transition-all duration-300"
        style={{
          left: getPosition(score),
          marginLeft: "-6px",
        }}
      ></div>
    </div>
  );
};

export default ConfidenceIndicator;
