import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import './tabLayout.css';

const GraphTabLayout = ({ graphActiveIndex, setGraphActiveIndex, children }) => {

    const handleTabChange = (e) => {
        setGraphActiveIndex(e.index);
    };

    return (
        <TabView activeIndex={graphActiveIndex} onTabChange={handleTabChange}>
            <TabPanel header="Prediction">{graphActiveIndex === 0 && children}</TabPanel>
            <TabPanel header="Model Health">{graphActiveIndex === 1 && children}</TabPanel>
            <TabPanel header="Utilization">{graphActiveIndex === 2 && children}</TabPanel>
            {/* <TabPanel header="Prediction vs Time">{graphActiveIndex === 3 && children}</TabPanel> */}
        </TabView>
    );
};

export default GraphTabLayout;
