import React from "react";

const CScore = ({ score }) => {
  return (
    <div className="flex items-center">
      <span className="text-gray-600 text-xs mr-2">Confidence:</span>
      <span className="text-xs text-gray-700 mr-2 font-semibold">{score}</span>
      <div className="w-24 h-2 bg-gray-200 rounded-full relative">
        <div className="absolute top-0 left-0 h-full w-1/3 rounded-l-full bg-red-600"></div>
        <div className="absolute top-0 left-1/3 h-full w-1/3 bg-yellow-400"></div>
        <div className="absolute top-0 left-2/3 h-full w-1/3 rounded-r-full bg-green-600"></div>
        <div
          className="absolute top-1/2 transform -translate-y-1/2 h-4 w-4 rounded-full bg-white border-1 transition-all duration-300"
          style={{
            left:
              score === "Low"
                ? "16.67%"
                : score === "Medium"
                ? "50%"
                : "83.33%",
            marginLeft: "-6px",
          }}
        ></div>
      </div>
    </div>
  );
};

export default CScore;
