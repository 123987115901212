import axios from "axios";
import { REPORT_API_BASE_URL } from "../utils/config";

const ReportService = {
  createReport: async (reportData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/create_report`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getAllReports: async (orgId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${REPORT_API_BASE_URL}s`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.reports;
    } catch (error) {
      throw error;
    }
  },

  getReportById: async (reportId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${REPORT_API_BASE_URL}/${reportId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.report;
    } catch (error) {
      throw error;
    }
  },
  visualizeReport: async ({ report_id, question_id, ...reportData }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/visualize`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  knowledgeGraphForQuestion: async ({
    report_id,
    question_id,
    ...reportData
  }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/knowledge-graph`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  knowledgeGraphForReport: async ({ report_id, ...reportData }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/knowledge-graph`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  tablesUsed: async ({ report_id, question_id, ...reportData }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${question_id}/tables-used`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  tablesUsedForQuestion: async ({ report_id, question_id, ...reportData }) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${report_id}/question/${question_id}/tables-used`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateReport: async (reportId, reportData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${REPORT_API_BASE_URL}/${reportId}`,
        reportData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteReport: async (reportId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${REPORT_API_BASE_URL}/${reportId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  addQuestion: async (reportId, questionData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${REPORT_API_BASE_URL}/${reportId}/add_question`,
        questionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.question;
    } catch (error) {
      throw error;
    }
  },
  updateQuestion: async (reportId, questionId, questionData) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.put(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}`,
        questionData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  deleteQuestion: async (reportId, questionId) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${REPORT_API_BASE_URL}/${reportId}/question/${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ReportService;
