import axios from 'axios';
import { ADMIN_API_BASE_URL } from '../utils/config';

const Activity_ADMIN_API_BASE_URL = `${ADMIN_API_BASE_URL}/activity`;

const ActivityService = {
    getAllActivityLog: async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(Activity_ADMIN_API_BASE_URL, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getActivityLogById: async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${Activity_ADMIN_API_BASE_URL}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    addActivityLog: async (activityLog, userId) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(Activity_ADMIN_API_BASE_URL, activityLog, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'user-id': userId
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default ActivityService;
