import axios from 'axios';
import { CHATBOT_API_BASE_URL } from '../utils/config';

const CHAT_API_BASE_URL = `${CHATBOT_API_BASE_URL}/chat`;

const ChatService = {
    getAnswer: async (payload) => {
        try {
            const response = await axios.post(CHAT_API_BASE_URL, payload, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            return response.data; 
        } catch (error) {
            throw error;
        }
    }
};

export default ChatService;
