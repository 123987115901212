import { FaDownload } from "react-icons/fa";

const ChatHeader = ({ onExport }) => (
  <div className="py-3 px-4 bg-white flex justify-between items-center border-b border-[#d1d1d1] shadow-sm">
    <h2 className="m-0 text-lg font-semibold text-gray-800">Data Talk</h2>
    <button
      onClick={onExport}
      className="bg-white text-gray-800 rounded-md py-1.5 px-3 text-sm font-semibold cursor-pointer transition-all duration-300 ease-in-out hover:bg-gray-50 flex items-center"
    >
      <FaDownload className="w-4 h-4 mr-2" />
      Export
    </button>
  </div>
);

export default ChatHeader;
