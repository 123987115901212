import React, { useState, useEffect, useRef } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  FaEllipsisH,
  FaComment,
  FaMagic,
  FaProjectDiagram,
  FaTable,
} from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import CScore from "./cscore";
import References from "./references";
import CommentSection from "./comments-section";
import { AiOutlineClose, AiOutlineCopy, AiOutlineDelete } from "react-icons/ai";
import AssigneeSelector from "./assignee-selector";
import LabelSelector from "./label-selector";
import ReportService from "../../service/ReportService";
import VisualizationComponent from "./visualization-component";
import KnowledgeGraphComponent from "./knowledge-graph-component";
import TablesUsedComponent from "./tables-used-component";

const users = [
  { id: 1, name: "Vivek Vishwakarma", avatar: null },
  { id: 2, name: "Shivansh Mehendiratta", avatar: null },
  { id: 3, name: "Sainyam Galhotra", avatar: null },
];
const allLabels = [
  { text: "Bug", color: "red" },
  { text: "Feature", color: "purple" },
  { text: "Go to market", color: "pink" },
  { text: "Improvement", color: "blue" },
  { text: "To Discuss", color: "yellow" },
];

const QuestionItem = ({ question, report, onDeleteQuestion, setReport }) => {
  const [newQuestionText, setNewQuestionText] = useState(question.question);
  const [comments, setComments] = useState(question.comments || []);
  const [newComment, setNewComment] = useState("");
  const [status, setStatus] = useState(question.status || "draft");
  const [showComments, setShowComments] = useState(false);
  const [isEditing, setIsEditing] = useState(!question.answer);
  const [answer, setAnswer] = useState(question.answer || "");
  const [isGeneratingAnswer, setIsGeneratingAnswer] = useState(false);
  const [assignee, setAssignee] = useState(question.assignee || null);
  const [labels, setLabels] = useState(question.labels || []);
  const questionInputRef = useRef(null);
  const [showVisualization, setShowVisualization] = useState(false);
  const [isVisualizationLoading, setIsVisualizationLoading] = useState(false);
  const [showKnowledgeGraph, setShowKnowledgeGraph] = useState(false);
  const [isKnowledgeGraphLoading, setIsKnowledgeGraphLoading] = useState(false);
  const [showTablesUsed, setShowTablesUsed] = useState(false);
  const [isTablesUsedLoading, setIsTablesUsedLoading] = useState(false);

  useEffect(() => {
    setNewQuestionText(question.question);
    setIsEditing(!question.answer);
    setAnswer(question.answer || "");
    setComments(question.comments || []);
    setAssignee(question.assignee || null);
    setLabels(question.labels || []);
  }, [question]);

  useEffect(() => {
    if (isEditing && questionInputRef.current) {
      questionInputRef.current.focus();
    }
  }, [isEditing]);

  const updateReport = async (updatedQuestions) => {
    if (!Array.isArray(updatedQuestions)) {
      console.error("updateReport requires an array of questions");
      return;
    }

    const updatedReport = {
      ...report,
      questions: updatedQuestions,
      updatedAt: new Date().toISOString(),
    };

    setReport(updatedReport);

    try {
      const updatedQuestion = updatedQuestions.find(
        (q) => q.question_id === question.question_id
      );
      await ReportService.updateQuestion(
        report.report_id,
        question.question_id,
        updatedQuestion
      );
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  const handleContainerClick = () => {
    if (isEditing && questionInputRef.current) {
      questionInputRef.current.focus();
    }
  };

  const handleLabelsChange = (newLabels) => {
    setLabels(newLabels);
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id ? { ...q, labels: newLabels } : q
    );
    updateReport(updatedQuestions);
  };

  const handleQuestionChange = (e) => {
    setNewQuestionText(e.target.value);
  };

  const handleGenerateAnswer = async () => {
    setIsGeneratingAnswer(true);
    const questionData = {
      question: newQuestionText,
      report_id: report.report_id,
    };

    try {
      const response = await ReportService.addQuestion(
        report.report_id,
        questionData
      );
      const { question_id, answer, question } = response;

      setAnswer(answer);
      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id
          ? {
              ...q,
              question_id,
              question: newQuestionText,
              answer: answer,
            }
          : q
      );
      updateReport(updatedQuestions);
      setIsEditing(false);
    } catch (error) {
      console.error("Error generating answer:", error);
      setAnswer({
        text: "Error generating answer. Please try again.",
        cscore: "Low",
        references: [],
      });
    } finally {
      setIsGeneratingAnswer(false);
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSaveComment = () => {
    if (newComment.trim() === "") return;

    const newCommentObj = {
      id: Date.now(),
      text: newComment,
      user: {
        name: "Current User",
        avatar: null,
      },
      createdAt: new Date().toISOString(),
    };

    const updatedComments = [...comments, newCommentObj];
    setComments(updatedComments);
    setNewComment("");

    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id
        ? { ...q, comments: updatedComments }
        : q
    );
    updateReport(updatedQuestions);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id
        ? { ...q, status: e.target.value }
        : q
    );
    updateReport(updatedQuestions);
  };

  const handleCopy = () => {
    const textToCopy = `Question: ${question.question}\nAnswer: ${answer.text}`;
    navigator.clipboard.writeText(textToCopy);
  };

  const handleDelete = () => {
    onDeleteQuestion(question.question_id);
  };

  const handleAssigneeChange = (user) => {
    setAssignee(user);
    const updatedQuestions = report.questions.map((q) =>
      q.question_id == question.question_id ? { ...q, assignee: user.id } : q
    );
    updateReport(updatedQuestions);
  };

  const handleKeyDown = (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
      handleGenerateAnswer();
    }
  };

  const handleVisualize = async () => {
    setShowVisualization(true);
    setIsVisualizationLoading(true);
    try {
      const response = await ReportService.visualizeReport({
        question_id: question.question_id,
        report_id: report.report_id,
        answer: question.answer.text,
      });

      const updatedQuestion = {
        ...question,
        visualization: response.visualization,
      };

      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id ? updatedQuestion : q
      );

      updateReport(updatedQuestions);
    } catch (error) {
      console.error("Error visualizing report:", error);
    } finally {
      setIsVisualizationLoading(false);
    }
  };

  const handleKnowledgeGraph = async () => {
    setShowKnowledgeGraph(true);
    setIsKnowledgeGraphLoading(true);
    try {
      const response = await ReportService.knowledgeGraphForQuestion({
        question_id: question.question_id,
        report_id: report.report_id,
      });
      const updatedQuestion = {
        ...question,
        knowledgeGraph: response.knowledgeGraph,
      };
      console.log(updatedQuestion);

      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id ? updatedQuestion : q
      );

      updateReport(updatedQuestions);
    } catch (error) {
      console.error("Error generating knowledge graph:", error);
    } finally {
      setIsKnowledgeGraphLoading(false);
    }
  };

  const removeVisualization = () => {
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id ? { ...q, visualization: null } : q
    );
    setShowVisualization(false);
    updateReport(updatedQuestions);
  };

  const removeKnowledgeGraph = () => {
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id
        ? { ...q, knowledgeGraph: null }
        : q
    );
    setShowKnowledgeGraph(false);
    updateReport(updatedQuestions);
  };
  const handleTablesUsed = async () => {
    setShowTablesUsed(true);
    setIsTablesUsedLoading(true);
    try {
      const response = await ReportService.tablesUsedForQuestion({
        question_id: question.question_id,
        report_id: report.report_id,
      });
      const updatedQuestion = {
        ...question,
        tablesUsed: response.tablesUsed,
      };

      const updatedQuestions = report.questions.map((q) =>
        q.question_id === question.question_id ? updatedQuestion : q
      );

      updateReport(updatedQuestions);
    } catch (error) {
      console.error("Error getting tables used:", error);
    } finally {
      setIsTablesUsedLoading(false);
    }
  };

  const removeTablesUsed = () => {
    const updatedQuestions = report.questions.map((q) =>
      q.question_id === question.question_id ? { ...q, tablesUsed: null } : q
    );
    setShowTablesUsed(false);
    updateReport(updatedQuestions);
  };
  return (
    <div
      onClick={handleContainerClick}
      className="mb-4 p-4 rounded-lg hover:bg-gray-50 transition-colors duration-200"
    >
      <div className="flex mb-2 items-center justify-between">
        <div>
          {
            <h3 className="text-lg text-gray-900">
              {isEditing ? `Add a question` : question.question}
            </h3>
          }
        </div>
        {isEditing ? (
          <button
            onClick={handleDelete}
            className="text-gray-500 bg-transparent hover:text-gray-700"
          >
            <AiOutlineClose />
          </button>
        ) : (
          <select
            value={status}
            onChange={handleStatusChange}
            className="text-sm text-gray-500 bg-transparent border-none focus:ring-0 hover:bg-gray-200 rounded px-2 py-1"
          >
            <option value="draft">Draft</option>
            <option value="completed">Completed</option>
            <option value="pending review">Pending Review</option>
          </select>
        )}
      </div>
      {isEditing && (
        <textarea
          value={newQuestionText}
          ref={questionInputRef}
          onChange={handleQuestionChange}
          onKeyDown={handleKeyDown}
          className="w-full p-2 mb-2 border-none focus:ring-0 bg-gray-100 rounded-md placeholder-gray-400 text-gray-700"
          rows="3"
          placeholder="Enter your question here..."
        />
      )}
      {answer.text ? (
        <>
          <div className="mt-2 p-2 bg-gray-100 rounded-md">
            <ReactMarkdown>{answer.text}</ReactMarkdown>
          </div>
          <div className="mt-2 flex items-center justify-between">
            <References references={answer.references} />
            <CScore score={answer.cscore} />
          </div>
        </>
      ) : (
        <button
          onClick={handleGenerateAnswer}
          className="mt-2 text-blue-600 hover:bg-blue-50 px-2 py-1 rounded text-sm"
          disabled={isGeneratingAnswer}
        >
          {isGeneratingAnswer ? "Generating..." : "Ask AI"}
        </button>
      )}
      {!isEditing && (
        <>
          <div className="flex justify-between items-center mt-2 mb-2">
            <div className="flex items-center space-x-2">
              {question.visualization ? (
                <div className="flex items-center bg-gray-100 rounded-lg p-1">
                  <button
                    onClick={handleVisualize}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-l-md hover:bg-gray-200 transition-colors"
                    title="Regenerate Visualization"
                  >
                    <FaMagic className="h-3.5 w-3.5" />
                  </button>
                  <div className="w-px h-4 bg-gray-300" />
                  <button
                    onClick={removeVisualization}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-r-md hover:bg-gray-200 transition-colors"
                    title="Remove Visualization"
                  >
                    <AiOutlineClose className="h-3.5 w-3.5" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleVisualize}
                  className="text-gray-500 hover:text-gray-700 bg-transparent flex items-center space-x-1.5 hover:bg-gray-100 rounded-md px-2 py-1 transition-colors"
                >
                  <FaMagic className="h-3.5 w-3.5" />
                  <span className="text-sm">Visualize</span>
                </button>
              )}

              {question.knowledgeGraph ? (
                <div className="flex items-center bg-gray-100 rounded-lg p-1">
                  <button
                    onClick={handleKnowledgeGraph}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-l-md hover:bg-gray-200 transition-colors"
                    title="Regenerate Knowledge Graph"
                  >
                    <FaProjectDiagram className="h-3.5 w-3.5" />
                  </button>
                  <div className="w-px h-4 bg-gray-300" />
                  <button
                    onClick={removeKnowledgeGraph}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-r-md hover:bg-gray-200 transition-colors"
                    title="Remove Knowledge Graph"
                  >
                    <AiOutlineClose className="h-3.5 w-3.5" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleKnowledgeGraph}
                  className="text-gray-500 hover:text-gray-700 bg-transparent flex items-center space-x-1.5 hover:bg-gray-100 rounded-md px-2 py-1 transition-colors"
                >
                  <FaProjectDiagram className="h-3.5 w-3.5" />
                  <span className="text-sm">Knowledge Graph</span>
                </button>
              )}

              {question.tablesUsed ? (
                <div className="flex items-center bg-gray-100 rounded-lg p-1">
                  <button
                    onClick={handleTablesUsed}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-l-md hover:bg-gray-200 transition-colors"
                    title="Refresh Tables Used"
                  >
                    <FaTable className="h-3.5 w-3.5" />
                  </button>
                  <div className="w-px h-4 bg-gray-300" />
                  <button
                    onClick={removeTablesUsed}
                    className="text-gray-600 hover:text-gray-800 p-1.5 rounded-r-md hover:bg-gray-200 transition-colors"
                    title="Remove Tables Used"
                  >
                    <AiOutlineClose className="h-3.5 w-3.5" />
                  </button>
                </div>
              ) : (
                <button
                  onClick={handleTablesUsed}
                  className="text-gray-500 hover:text-gray-700 bg-transparent flex items-center space-x-1.5 hover:bg-gray-100 rounded-md px-2 py-1 transition-colors"
                >
                  <FaTable className="h-3.5 w-3.5" />
                  <span className="text-sm">Tables Used</span>
                </button>
              )}
            </div>
            <div className="flex items-center">
              <button
                onClick={() => setShowComments(!showComments)}
                className="text-gray-500 bg-transparent hover:bg-gray-200 rounded p-1 mr-2 relative"
              >
                <FaComment className="h-4 w-4" />
                {comments.length > 0 && (
                  <span className="absolute -top-1 -right-1 bg-blue-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                    {comments.length}
                  </span>
                )}
              </button>
              <AssigneeSelector
                assignee={users.find((user) => user.id == assignee)}
                users={users}
                onAssigneeChange={handleAssigneeChange}
              />
              <LabelSelector
                labels={labels}
                allLabels={allLabels}
                onLabelsChange={handleLabelsChange}
              />

              <Menu as="div" className="relative inline-block text-left">
                <MenuButton className="text-gray-500 bg-transparent flex hover:bg-gray-200 rounded p-1">
                  <FaEllipsisH className="h-4 w-4" aria-hidden="true" />
                </MenuButton>
                <MenuItems className="absolute border border-solid border-gray-200 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-lg overflow-hidden bg-transparent backdrop-blur-lg shadow-lg border border-gray-200 focus:outline-none">
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        onClick={handleCopy}
                        className={`${
                          focus
                            ? "bg-gray-100/70 text-gray-900"
                            : "bg-transparent text-gray-700"
                        } group flex w-full items-center px-4 py-2 text-sm`}
                      >
                        <AiOutlineCopy className="mr-2" />
                        Copy
                      </button>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ focus }) => (
                      <button
                        onClick={handleDelete}
                        className={`${
                          focus
                            ? "bg-gray-100/70 text-gray-900"
                            : "bg-transparent text-gray-700"
                        } group border-t border-solid border-0 flex w-full items-center px-4 py-2 text-sm hover:text-red-600`}
                      >
                        <AiOutlineDelete className="mr-2" />
                        Delete Question
                      </button>
                    )}
                  </MenuItem>
                </MenuItems>
              </Menu>
            </div>
          </div>
          <div>
            {labels.map((label) => (
              <span
                key={label.text}
                className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-${label.color}-100 text-${label.color}-800`}
              >
                <div
                  style={{ backgroundColor: label.color }}
                  className={`w-2 h-2 rounded-full mr-1`}
                />
                {label.text}
              </span>
            ))}
          </div>
        </>
      )}
      {showComments && (
        <CommentSection
          comments={comments}
          onAddComment={handleSaveComment}
          onCommentChange={handleCommentChange}
          newComment={newComment}
        />
      )}
      {showVisualization && (
        <VisualizationComponent
          visualization={question.visualization}
          isLoading={isVisualizationLoading}
        />
      )}
      {showKnowledgeGraph && (
        <KnowledgeGraphComponent
          data={question.knowledgeGraph}
          isLoading={isKnowledgeGraphLoading}
        />
      )}
      {showTablesUsed && (
        <TablesUsedComponent
          data={question.tablesUsed}
          isLoading={isTablesUsedLoading}
        />
      )}
    </div>
  );
};

export default QuestionItem;
