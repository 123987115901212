import { FaEdit } from "react-icons/fa";

const UserMessage = ({
  entry,
  index,
  editingIndex,
  editedMessage,
  onEdit,
  onSaveEdit,
  onCancelEdit,
  setEditedMessage,
}) => {
  const isEditing = editingIndex === index;

  return (
    <div
      className={`relative group p-3 rounded-xl bg-[#cce5ff] self-end rounded-tl-none rounded-br-none ${
        isEditing ? "w-full" : "max-w-[70%]"
      }`}
    >
      {!isEditing && (
        <button
          onClick={() => onEdit(index)}
          className="absolute top-2 right-full mr-2 size-8 bg-gray-800 text-white rounded-full opacity-0 group-hover:opacity-100 transition-all duration-200 hover:bg-gray-700"
          aria-label="Edit message"
          title="Edit message"
        >
          <FaEdit className="w-3.5 h-3.5" />
        </button>
      )}

      <div className="break-words">
        {isEditing ? (
          <EditForm
            editedMessage={editedMessage}
            setEditedMessage={setEditedMessage}
            onSaveEdit={onSaveEdit}
            onCancelEdit={onCancelEdit}
          />
        ) : (
          <div>{entry.text}</div>
        )}
      </div>
    </div>
  );
};

const EditForm = ({
  editedMessage,
  setEditedMessage,
  onSaveEdit,
  onCancelEdit,
}) => (
  <div className="w-full">
    <textarea
      value={editedMessage}
      onChange={(e) => setEditedMessage(e.target.value)}
      className="w-full p-4 rounded-lg border border-gray-200 bg-white/80 backdrop-blur-sm 
        focus:outline-none focus:ring-2 focus:ring-blue-500/20 focus:border-blue-500/40
        text-[15px] leading-relaxed resize-none transition-all duration-200
        placeholder:text-gray-400"
      autoFocus
      rows={4}
      placeholder="Edit your message..."
      onKeyDown={(e) => {
        if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
          e.preventDefault();
          onSaveEdit();
        }
      }}
    />
    <div className="mt-4 flex justify-end space-x-3">
      <button
        onClick={onCancelEdit}
        className="px-5 py-2 text-sm font-medium text-gray-600 rounded-full 
          hover:bg-gray-100 active:bg-gray-200 transition-all duration-200"
      >
        Cancel
      </button>
      <button
        onClick={onSaveEdit}
        className="px-5 py-2 text-sm font-medium text-white bg-blue-500 rounded-full
          shadow-sm shadow-blue-500/20 hover:bg-blue-600 active:bg-blue-700
          transition-all duration-200"
      >
        Save Changes
      </button>
    </div>
  </div>
);

export default UserMessage;
