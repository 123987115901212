import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  AiOutlinePushpin,
  AiOutlineLink,
  AiOutlineCopy,
  AiOutlineCloud,
  AiOutlineDelete,
  AiOutlineSetting,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { FaEllipsisH } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import ReportService from "../../service/ReportService";

const ReportItem = ({
  report,
  isActive,
  reports,
  onUpdateReports,
  onShowToast,
}) => {
  const navigate = useNavigate();

  const openReport = () => {
    navigate(`/portal/reports/${report.report_id}`);
  };

  const handlePin = async () => {
    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? {
            ...r,
            isPinned: !r.isPinned,
            updatedAt: new Date().toISOString(),
          }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, {
        isPinned: !report.isPinned,
      });
      const action = report.isPinned ? "unpinned" : "pinned";
      onShowToast("success", `Report ${action}`, `Report has been ${action}`);
    } catch (error) {
      onUpdateReports(reports);
      onShowToast(
        "error",
        "Update Failed",
        "Failed to update report pin status"
      );
      console.error("Error updating report pin status:", error);
    }
  };

  const handleDelete = async () => {
    const previousReports = reports;
    const updatedReports = reports.filter(
      (r) => r.report_id !== report.report_id
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.deleteReport(report.report_id);
      onShowToast("info", "Report Deleted", `Report has been deleted`);
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Delete Failed", "Failed to delete report");
      console.error("Error deleting report:", error);
    }
  };

  const handleDuplicate = async () => {
    const newReport = {
      ...report,
      report_id: Math.floor(Math.random() * 1000000),
      title: `${report.title} (Duplicate)`,
      name: `${report.name} (Duplicate)`,
      isPinned: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    onUpdateReports([...reports, newReport]);

    try {
      const createdReport = await ReportService.createReport({
        name: newReport.name,
        title: newReport.title,
        is_pinned: newReport.isPinned,
      });

      // Update with actual server data
      const finalReports = reports.concat({
        ...newReport,
        report_id: createdReport.report_id,
      });
      onUpdateReports(finalReports);

      onShowToast(
        "success",
        "Report Duplicated",
        `${report.name} has been duplicated`
      );
      navigate(`/portal/reports/${createdReport.report_id}`);
    } catch (error) {
      onUpdateReports(reports);
      onShowToast("error", "Duplication Failed", "Failed to duplicate report");
      console.error("Error duplicating report:", error);
    }
  };

  const handleReportNameChange = async (newName) => {
    const previousReports = reports;

    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? { ...r, name: newName, updatedAt: new Date().toISOString() }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, { name: newName });
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Update Failed", "Failed to update report name");
      console.error("Error updating report name:", error);
    }
  };

  const handleCopyLink = () => {
    const reportLink = `${window.location.origin}/portal/reports/${report.report_id}`;
    navigator.clipboard
      .writeText(reportLink)
      .then(() => {
        onShowToast(
          "success",
          "Link Copied",
          "Report link has been copied to clipboard"
        );
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
        onShowToast(
          "error",
          "Copy Failed",
          "Failed to copy report link to clipboard"
        );
      });
  };

  const handleCopyShareableLink = () => {
    const shareableLink = `${window.location.origin}/preview/report/${report.report_id}`;
    navigator.clipboard
      .writeText(shareableLink)
      .then(() => {
        onShowToast(
          "success",
          "Shareable Link Copied",
          "Shareable link has been copied to clipboard"
        );
      })
      .catch((err) => {
        console.error("Failed to copy shareable link: ", err);
        onShowToast(
          "error",
          "Copy Failed",
          "Failed to copy shareable link to clipboard"
        );
      });
  };

  const handleArchive = async () => {
    const previousReports = reports;
    const updatedReports = reports.map((r) =>
      r.report_id === report.report_id
        ? { ...r, archived: true, updatedAt: new Date().toISOString() }
        : r
    );
    onUpdateReports(updatedReports);

    try {
      await ReportService.updateReport(report.report_id, { archived: true });
      onShowToast("success", "Report Archived", `Report has been archived`);
    } catch (error) {
      onUpdateReports(previousReports);
      onShowToast("error", "Archive Failed", "Failed to archive report");
      console.error("Error archiving report:", error);
    }
  };

  const handleSettings = () => {
    navigate(`/portal/reports/${report.report_id}/settings`);
  };

  return (
    <li
      className={`cursor-pointer p-2 rounded text-sm group ${
        isActive
          ? "bg-gray-100 text-gray-800"
          : "text-gray-600 hover:bg-gray-100 hover:text-gray-800"
      }`}
    >
      <div className="flex items-center justify-between">
        <div onClick={openReport} className="flex items-center flex-grow">
          <span className="mr-2">{report.isPinned ? "📌" : "📄"}</span>
          <input
            type="text"
            value={report.name}
            onChange={(e) => handleReportNameChange(e.target.value)}
            className="bg-transparent border-none focus:outline-none"
            onClick={(e) => e.stopPropagation()}
          />
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <MenuButton className="invisible bg-transparent hover:bg-gray-200 rounded flex items-center group-hover:visible">
            <FaEllipsisH className="text-gray-600 hover:text-gray-800" />
          </MenuButton>
          <MenuItems className="absolute border border-solid border-gray-200 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-lg overflow-hidden bg-transparent backdrop-blur-lg shadow-lg border border-gray-200 focus:outline-none">
            <MenuItem>
              {({ focus }) => (
                <button
                  className={`${
                    focus
                      ? "bg-gray-100/70 text-gray-900"
                      : "bg-transparent text-gray-700"
                  } group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => handlePin(report.report_id)}
                >
                  <AiOutlinePushpin className="mr-2" />
                  {report.isPinned ? "Unpin" : "Pin"}
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <button
                  className={`${
                    focus
                      ? "bg-gray-100/70 text-gray-900"
                      : "bg-transparent text-gray-700"
                  } group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => handleCopyLink(report.report_id)}
                >
                  <AiOutlineLink className="mr-2" />
                  Copy Link
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <button
                  className={`${
                    focus
                      ? "bg-gray-100/70 text-gray-900"
                      : "bg-transparent text-gray-700"
                  } group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => handleCopyShareableLink(report.report_id)}
                >
                  <AiOutlineShareAlt className="mr-2" />
                  Copy Shareable Link
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <button
                  className={`${
                    focus
                      ? "bg-gray-100/70 text-gray-900"
                      : "bg-transparent text-gray-700"
                  } group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => handleDuplicate(report.report_id)}
                >
                  <AiOutlineCopy className="mr-2" />
                  Duplicate
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <button
                  className={`${
                    focus
                      ? "bg-gray-100/70 text-gray-900"
                      : "bg-transparent text-gray-700"
                  } group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => handleArchive(report.report_id)}
                >
                  <AiOutlineCloud className="mr-2" />
                  Archive
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <button
                  className={`${
                    focus
                      ? "bg-gray-100/70 text-gray-900"
                      : "bg-transparent text-gray-700"
                  } group flex w-full items-center px-4 py-2 text-sm`}
                  onClick={() => handleSettings(report.report_id)}
                >
                  <AiOutlineSetting className="mr-2" />
                  Settings
                </button>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <button
                  className={`${
                    focus
                      ? "bg-gray-100/70 text-gray-900"
                      : "bg-transparent text-gray-700"
                  } group border-t border-solid border-0 flex w-full items-center px-4 py-2 text-sm hover:text-red-600`}
                  onClick={() => handleDelete(report.report_id)}
                >
                  <AiOutlineDelete className="mr-2" />
                  Delete
                </button>
              )}
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
    </li>
  );
};

export default ReportItem;
