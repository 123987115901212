import React, { useMemo } from "react";
import KnowledgeGraph from "../graph/knowledge-graph";
import { AiOutlineNodeIndex } from "react-icons/ai";

const KnowledgeGraphComponent = React.memo(({ data, isLoading }) => {
  const renderContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="animate-pulse flex flex-col space-y-4 max-w-md mx-auto h-64">
          <div className="h-8 bg-slate-200 rounded w-1/4"></div>
          <div className="flex-1 bg-slate-200 rounded"></div>
          <div className="h-4 bg-slate-200 rounded w-full"></div>
        </div>
      );
    }

    const nodes = data?.nodes || [];
    const links = data?.links || [];

    return (
      <div className="bg-white shadow-md rounded-lg p-4 mb-4">
        <div className="flex items-center gap-2 mb-4">
          <AiOutlineNodeIndex className="text-gray-600" />
          <h4 className="text-md font-semibold">Knowledge Graph</h4>
        </div>
        <div className="h-[600px]">
          <KnowledgeGraph nodes={nodes} links={links} showControls={true} />
        </div>
      </div>
    );
  }, [data, isLoading]);

  return renderContent;
});

export default KnowledgeGraphComponent;
