import axios from 'axios';
import { AWS_API_BASE_URL } from '../utils/config';

const S3Service = {
    getAllObjects: async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${AWS_API_BASE_URL}/s3data`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getObjectDetail: async (fileName) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${AWS_API_BASE_URL}/s3data/${fileName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    getFileList: async (folderName) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${AWS_API_BASE_URL}/s3data/files/${folderName}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    uploadFile: async (objectKey, file) => {
        const token = localStorage.getItem('token');
        try {
            const arrayBuffer = await file.arrayBuffer();
            const fileBytes = new Uint8Array(arrayBuffer);

            const response = await axios.post(`${AWS_API_BASE_URL}/s3data/upload/${objectKey}`, fileBytes, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/octet-stream' 
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    uploadCsvAndCreateFolder: async (files) => {
        const token = localStorage.getItem('token');
        try {
            // Create FormData object to send multiple files
            const formData = new FormData();
            files.forEach(file => {
                formData.append('files', file); // 'files' matches @RequestParam("files")
            });
    
            const response = await axios.post(`${AWS_API_BASE_URL}/s3data/upload`, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data' // Required for file uploads
                }
            });
    
            return response.data;
        } catch (error) {
            throw error;
        }
    },
    
    downloadFile: async (filepath) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${AWS_API_BASE_URL}/s3data/download/${filepath}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                responseType: 'blob' 
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

export default S3Service;
