import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import ReportItem from "./report-item";

const ReportList = ({
  reports,
  activeReportId,
  onCreateNewReport,
  onUpdateReports,
  onShowToast,
  isCreatingReport,
  isLoadingReports,
}) => {
  const sortedReports = [...reports].sort((a, b) => {
    if (a.isPinned === b.isPinned) {
      return 0;
    }
    return a.isPinned ? -1 : 1;
  });

  const renderSkeleton = () => {
    return Array(5)
      .fill(0)
      .map((_, index) => (
        <div
          key={index}
          className="animate-pulse flex items-center p-2 rounded"
        >
          <div className="w-4 h-4 bg-gray-200 rounded mr-2"></div>
          <div className="flex-grow h-4 bg-gray-200 rounded"></div>
        </div>
      ));
  };

  return (
    <div className="col-span-1 border-r border-0 border-gray-300 h-full border-solid bg-white p-4 overflow-y-auto">
      <button
        onClick={onCreateNewReport}
        disabled={isCreatingReport || isLoadingReports}
        className="w-full mb-4 bg-inherit border border-solid border-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-100 transition-colors flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isCreatingReport ? (
          <span className="animate-spin mr-2">⌛</span>
        ) : (
          <AiOutlinePlus className="mr-2" style={{ fontSize: "12px" }} />
        )}
        {isCreatingReport ? "Creating..." : "New Report"}
      </button>
      <div className="space-y-1">
        {isLoadingReports ? (
          renderSkeleton()
        ) : (
          <ul className="space-y-1">
            {sortedReports.map((report) => (
              <ReportItem
                key={report.report_id}
                report={report}
                isActive={report.report_id == activeReportId}
                reports={reports}
                onUpdateReports={onUpdateReports}
                onShowToast={onShowToast}
              />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ReportList;
