import React from "react";
import { Routes, Route } from "react-router-dom";
import ReportPreview from "../../components/reports/report-preview";

const Preview = () => {
  return (
    <div className="flex flex-col bg-accent h-screen">
      <div className="flex h-full flex-1">
        <div className="flex-1 m-3 bg-white border shadow-md rounded-xl z-1 overflow-y-auto">
          <Routes>
            <Route path="/report/:reportId" element={<ReportPreview />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Preview;
