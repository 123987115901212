import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { formatDate, formatLastUpdated } from "../util/dateUtils";
import ReportService from "../../service/ReportService";
import VisualizationComponent from "./visualization-component";

const ReportPreview = () => {
  const { reportId } = useParams();
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchReport = async () => {
      setIsLoading(true);
      try {
        const reportData = await ReportService.getReportById(reportId);
        setReport(reportData);
      } catch (error) {
        console.error("Error fetching report:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reportId) {
      fetchReport();
    }
  }, [reportId]);

  if (isLoading) {
    return (
      <div className="w-full max-w-4xl mx-auto p-6 bg-white h-full">
        <div className="mb-8 animate-pulse">
          <div className="h-12 w-3/4 bg-gray-200 rounded mb-2"></div>
          <div className="flex items-center text-sm text-gray-500">
            <div className="h-4 w-32 bg-gray-200 rounded mr-2"></div>
            <span>•</span>
            <div className="h-4 w-40 bg-gray-200 rounded ml-2"></div>
          </div>
        </div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="mb-6 animate-pulse">
            <div className="h-24 bg-gray-200 rounded mb-4"></div>
            <div className="h-16 bg-gray-100 rounded"></div>
          </div>
        ))}
      </div>
    );
  }

  if (!report) {
    return (
      <div className="w-full max-w-4xl mx-auto p-6 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">Report not found or failed to load.</p>
      </div>
    );
  }

  return (
    <div className="w-full max-w-4xl mx-auto p-6 bg-white h-full overflow-y-auto">
      {report.template_id === "template1" ? (
        // Default vertical layout
        <div className="mb-8">
          {report.logo && (
            <img
              src={report.logo}
              alt="Report Logo"
              className="h-16 w-16 object-contain mb-4"
            />
          )}
          <h1 className="font-bold text-4xl mb-2">{report.title}</h1>
          <div className="flex items-center text-sm text-gray-500">
            <span className="mr-2">
              Created: {formatDate(report.created_at)}
            </span>
            <span>•</span>
            <span className="ml-2">
              Last updated: {formatLastUpdated(report.updated_at)}
            </span>
          </div>
        </div>
      ) : report.template_id === "template2" ? (
        // Horizontal layout
        <div className="flex items-center border-b border-[#d1d1d1] border-0 border-solid pb-6 justify-between mb-8">
          <div className="flex items-center gap-6">
            {report.logo && (
              <img
                src={report.logo}
                alt="Report Logo"
                className="h-16 w-16 object-contain"
              />
            )}
            <h1 className="font-serif text-5xl">{report.title}</h1>
          </div>
          <div className="text-sm text-gray-500">
            <div>Created: {formatDate(report.created_at)}</div>
            <div>Last updated: {formatLastUpdated(report.updated_at)}</div>
          </div>
        </div>
      ) : report.template_id === "template3" ? (
        // Minimal layout with monospace font
        <div className="space-y-4 mb-8">
          {report.logo && (
            <img
              src={report.logo}
              alt="Report Logo"
              className="h-12 w-12 object-contain"
            />
          )}
          <h1 className="font-mono text-4xl">{report.title}</h1>
          <div className="font-mono text-xs text-gray-500">
            {formatDate(report.created_at)} • Updated{" "}
            {formatLastUpdated(report.updated_at)}
          </div>
        </div>
      ) : (
        // Default template
        <div className="space-y-4 mb-8">
          {report.logo && (
            <img
              src={report.logo}
              alt="Report Logo"
              className="h-16 w-16 object-contain"
            />
          )}
          <h1 className="text-4xl font-bold">{report.title}</h1>
          <div className="text-sm text-gray-500">
            Created: {formatDate(report.created_at)} • Last updated:{" "}
            {formatLastUpdated(report.updated_at)}
          </div>
        </div>
      )}

      {report.questions.map((question) => (
        <div key={question.id} className="mb-4 p-4 rounded-lg bg-gray-50">
          <h3 className="text-lg text-gray-900 mb-2">{question.question}</h3>

          {question.answer && (
            <div className="mt-2 p-2 bg-gray-100 rounded-md">
              <ReactMarkdown>{question.answer.text}</ReactMarkdown>
            </div>
          )}

          {question.visualization && (
            <div className="mt-4">
              <VisualizationComponent
                visualization={question.visualization}
                isLoading={false}
              />
            </div>
          )}
        </div>
      ))}

      {report.questions.length === 0 && (
        <div className="text-center py-8">
          <p className="text-gray-500">This report has no questions yet.</p>
        </div>
      )}
    </div>
  );
};

export default ReportPreview;
