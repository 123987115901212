import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import S3Service from '../../service/S3service';
import ActivityService from '../../service/ActivityService';
import TabLayout from '../layout/dataModelTabLayout';
import './data.css';
import { HttpStatusCode } from 'axios';

const Data = () => {
    const [files, setFiles] = useState([]);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(4);
    const toast = useRef(null);
    const fileUploadRef = useRef(null); // Ref for the FileUpload component
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            const filesData = await S3Service.getAllObjects();
            setFiles(filesData);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const logActivity = async (action, name) => {
        const user = JSON.parse(localStorage.getItem('user'));
        try {
            const activityLog = {
                email_id: user?.data?.email,
                action: `${action} - ${name}`
            };
            await ActivityService.addActivityLog(activityLog, user?.data?.id);
        } catch (error) {
            console.error('Failed to log activity:', error);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.files[0];
        const fileName = file.name;

        try {
            const response = await S3Service.uploadFile(fileName, file);

            if (response.status === "OK") {
                fetchFiles();
                showSuccessToast(response.message);
                fileUploadRef.current.clear(); 
                await logActivity('Uploaded File', fileName);
            } else {
                showErrorToast(response.message);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            showErrorToast('Error uploading file. Please try again.');
        }
    };

    const showSuccessToast = (message) => {
        toast.current.show({ severity: 'success', summary: 'Success', detail: message });
    };

    const showErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message });
    };

    const formatDate = (value) => {
        return new Date(value).toLocaleString();
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const handleFileClick = (filePath) => {
        const fileName = filePath.split('/').pop();
        localStorage.setItem('clickedFilePath', filePath);
        localStorage.setItem('clickedFileName', fileName);
        navigate(`/portal/file/${fileName}`);
    };

    return (
        <TabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
            <Toast ref={toast} position="bottom-right" />
            <div className="upload-section">
                <FileUpload
                    ref={fileUploadRef}
                    name="demo[]"
                    accept=".csv"
                    customUpload
                    uploadHandler={handleFileUpload}
                    maxFileSize={10000000}
                    emptyTemplate={<p className="m-0">Drag and drop a file here to upload.</p>}
                    chooseLabel="Select File"
                />
            </div>
            <div className="datatable-styles">
                <DataTable
                    className="p-datatable-striped p-datatable-smaller"
                    value={files}
                    sortField="last_modified"
                    sortOrder={-1}
                    first={first}
                    rows={rows}
                    onPage={onPageChange}
                    paginator={true}
                    rowsPerPageOptions={[4, 10, 20]}
                >
                    <Column
                        field="key"
                        header="File Name"
                        sortable
                        body={(rowData) => (
                            <button onClick={() => handleFileClick(rowData.key)}>
                                {rowData.key}
                            </button>
                        )}
                    />
                    <Column
                        field="last_modified"
                        header="Last Modified"
                        body={(rowData) => formatDate(rowData.last_modified)}
                        sortable
                    />
                    <Column
                        field="size"
                        header="Size"
                        sortable
                    />
                </DataTable>
            </div>
        </TabLayout>
    );
};

export default Data;
