import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TabLayout from '../../components/layout/dataModelTabLayout';
import CsvDataTable from '../table/csvTable/csvDataTable'
import S3Service from '../../service/S3service';
import CsvUtils from '../util/CsvUtil';
import './fileDetails.css';

const FileDetails = () => {
    const { fileName } = useParams();
    const [fileData, setFileData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [first, setFirst] = useState(0);
    const [rowsT, setRowsT] = useState(4);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedFileName, setSelectedFileName] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const filePathFromStorage = localStorage.getItem('clickedFilePath');
        if (filePathFromStorage) {
            fetchFileData(filePathFromStorage);
        }
        fetchFiles();
    }, []);

    const fetchFiles = async () => {
        try {
            const filesData = await S3Service.getAllObjects();
            setFiles(filesData);
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };

    const fetchFileData = async (filePath) => {
        try {
            const fileName = filePath.split('/').pop();
            const blob = await S3Service.downloadFile(filePath);
    
            // Convert the Blob to a string
            const csvString = await blob.text();
    
            // Parse the CSV string
            const { headers, data } = CsvUtils.parseCsv(csvString);
            setHeaders(headers);
            setFileData(data);
            setSelectedFileName(fileName); // Set selected file name
            setLoading(false);
        } catch (error) {
            console.error('Error fetching file data:', error);
            setLoading(false);
        }
    };

    const handleFileClick = (filePath) => {
        
        // Extract the file name from the path
        const fileName = filePath.split('/').pop();
        localStorage.setItem('clickedFilePath', filePath);
        localStorage.setItem('clickedFileName', fileName);
        // Navigate to the desired path
        navigate(`/portal/file/${fileName}`);
        fetchFileData(filePath);
    };


    const formatDate = (value) => {
        return new Date(value).toLocaleString();
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRowsT(event.rows);
    };

    return (
        <TabLayout activeIndex={activeIndex} setActiveIndex={setActiveIndex}>
            <div className='file-details-content'>
                <div className="upper-half">
                    <h2 className="title">{selectedFileName}</h2>
                    {fileData.length > 0 && (
                        <CsvDataTable headers={headers} data={fileData} />
                    )}
                    {loading && (
                        <div >
                            <p>Loading...</p>
                        </div>
                    )}
                </div>
                <div className="lower-half">
                    {activeIndex === 0 && (
                        <DataTable
                            className="p-datatable-striped p-datatable-smaller"
                            value={files}
                            sortField="last_modified"
                            sortOrder={-1}
                            first={first}
                            rows={rowsT}
                            onPage={onPageChange}
                            paginator={true}
                            rowsPerPageOptions={[4, 10, 20]}
                        >
                            <Column
                                field="key"
                                header="File Name"
                                sortable
                                body={(rowData) => (
                                    <button onClick={() => handleFileClick(rowData.key)}>
                                        {rowData.key}
                                    </button>
                                )}
                            />
                            <Column
                                field="last_modified"
                                header="Last Modified"
                                body={(rowData) => formatDate(rowData.last_modified)}
                                sortable
                            />
                            <Column
                                field="size"
                                header="Size"
                                sortable
                            />
                        </DataTable>
                    )}
                </div>
            </div>
        </TabLayout>
    );
};

export default FileDetails;
