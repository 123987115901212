import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './home.css';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import ModelService from '../../service/ModelService';
import ActivityService from '../../service/ActivityService';
import Papa from 'papaparse';
import S3Service from '../../service/S3service';

const Home = () => {
    const [chartData, setChartData] = useState({});
    const [utilizationChartData, setUtilizationChartData] = useState({});
    const [predictionChartData, setPredictionChartData] = useState({});
    const [healthChartData, setHealthChartData] = useState({});
    const [healthChartOptions, setHealthChartOptions] = useState({});
    const [predChartOptions, setPredChartOptions] = useState({});
    const [utilChartOptions, setUtilChartOptions] = useState({});
    const [jsonData, setJsonData] = useState([]);
    const [activityLog, setActivityLog] = useState([]);
    const [first, setFirst] = useState(0);
    const [first2, setFirst2] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const popupRef = useRef(null);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('Monthly');

    const chartDropdown = [
        { "label": "Daily", "value": "Daily" },
        { "label": "Monthly", "value": "Monthly" },
        { "label": "Quarterly", "value": "Quarterly" },
    ];

    const location = useLocation(); // Get the current location
    const [isHomeActive, setIsHomeActive] = useState(false); // Track if Home is active

    useEffect(() => {
        if (location.pathname === '/portal/home') {
            setIsHomeActive(true);
        } else {
            setIsHomeActive(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isHomeActive) {
            fetchData();
            fetchActivityLog();
            fetchUtilizationData(selectedTimePeriod);
            fetchPredictionData(selectedTimePeriod);
            fetchModelHealthData(selectedTimePeriod);
        }
        // Add event listener on mount
        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectedTimePeriod, isHomeActive]);

    const fetchData = async () => {
        try {
            const response = await ModelService.getAllJsonData(0,100);
            const sortedData = response?.data
                .map(item => ({
                    ...item,
                    created_at: new Date(item.created_at), // Convert Timestamp to Date object
                    product: Array.isArray(item.product) ? item.product.join(', ') : item.product
                }))
                .sort((a, b) => b.created_at - a.created_at) // Sort by date (most recent first)
                .map(item => ({
                    ...item,
                    created_at: formatDate(item.created_at)
                }));
            setJsonData(sortedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const formatDate = (date) => {
        return date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false // Use 24-hour time
        });
    };

    const fetchActivityLog = async () => {
        try {
            const response = await ActivityService.getAllActivityLog();
            const sortedData = response?.data?.map(item => ({
                ...item,
                timestamp: new Date(item.timestamp).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    hour12: false
                })
            })).sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            setActivityLog(sortedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getHealthChartOptions = () => {
        return {
            maintainAspectRatio: true,
            aspectRatio: 1.75,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Time',
                    },
                },
                y: {
                    grid: {
                        display: true,
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Confidence score (CS)',
                    },
                },
            },
            elements: {
                line: {
                    borderWidth: 3, // Adjust the thickness of the lines
                },
                point: {
                    radius: 2, // Adjust the size of the points
                },
            },
        };
    };

    const getPredictionChartOptions = () => {
        return {
            maintainAspectRatio: true,
            aspectRatio: 1.75,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Time',
                    },
                },
                y: {
                    grid: {
                        display: true,
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Prediction',
                    },
                },
            },
            elements: {
                line: {
                    borderWidth: 3, // Adjust the thickness of the lines
                },
                point: {
                    radius: 2, // Adjust the size of the points
                },
            },
        };
    };

    const getUtilizationChartOptions = () => {
        return {
            maintainAspectRatio: true,
            aspectRatio: 1.75,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Time',
                    },
                },
                y: {
                    grid: {
                        display: true,
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Utilization',
                    },
                },
            },
            elements: {
                line: {
                    borderWidth: 3, // Adjust the thickness of the lines
                },
                point: {
                    radius: 2, // Adjust the size of the points
                },
            },
        };
    };

    const fetchUtilizationData = async (timePeriod) => {
        try {
            const filePaths = [
                'tenant-1/Total/Total-Utilization-Data/bb-conf.csv',
                'tenant-1/Total/Total-Utilization-Data/CM-conf.csv',
                'tenant-1/Total/Total-Utilization-Data/s-conf.csv'
            ];
    
            const fetchPromises = filePaths.map(filepath =>
                S3Service.downloadFile(filepath).then(blob => blob.text())
            );
    
            const [bbConfText, cmConfText, sConfText] = await Promise.all(fetchPromises);
    
            const parsePromises = [bbConfText, cmConfText, sConfText].map(csvText => 
                new Promise(resolve => 
                    Papa.parse(csvText, { header: true, dynamicTyping: true, complete: results => resolve(results.data) })
                )
            );
    
            const [bbData, cmData, sData] = await Promise.all(parsePromises);
    
            const aggregateData = (data, label) => {
                let dates = data.map(row => {
                    const date = new Date(row.Date);
                    return date.toLocaleDateString();
                });
                let utilization = data.map(row => row.BB_utilization || row.CM_utilization || row.S_utilization);
    
                if (timePeriod === 'Monthly') {
                    const aggregatedData = aggregateByMonth(data);
                    dates = aggregatedData.dates;
                    utilization = aggregatedData.utilization;
                } else if (timePeriod === 'Quarterly') {
                    const aggregatedData = aggregateByQuarter(data);
                    dates = aggregatedData.dates;
                    utilization = aggregatedData.utilization;
                }
    
                return {
                    dates,
                    dataset: {
                        label,
                        data: utilization,
                        fill: false,
                        tension: 0.4,
                        borderDash: [5, 5],
                        borderColor: label === 'BB Utilization' ? '#42A5F5' : label === 'CM Utilization' ? '#FF6384' : '#18be23',
                    }
                };
            };
    
            const bbResult = aggregateData(bbData, 'BB Utilization');
            const cmResult = aggregateData(cmData, 'CM Utilization');
            const sResult = aggregateData(sData, 'S Utilization');
    
            const dates = bbResult.dates; // Assuming dates are the same for all datasets after aggregation
    
            const data = {
                labels: dates,
                datasets: [bbResult.dataset, cmResult.dataset, sResult.dataset]
            };
    
            setUtilizationChartData(data);
            setUtilChartOptions(getUtilizationChartOptions());
        } catch (error) {
            console.error('Error fetching or parsing CSV data:', error);
        }
    };

    const fetchPredictionData = async (timePeriod) => {
        try {
            const filePaths = [
                'tenant-1/Total/Total-Prediction-Data/bb-pred.csv',
                'tenant-1/Total/Total-Prediction-Data/CM-conf.csv',
                'tenant-1/Total/Total-Prediction-Data/s-conf.csv'
            ];
    
            const fetchPromises = filePaths.map(filepath =>
                S3Service.downloadFile(filepath).then(blob => blob.text())
            );
    
            const [bbPredText, cmPredText, sPredText] = await Promise.all(fetchPromises);
    
            const parsePromises = [bbPredText, cmPredText, sPredText].map(csvText => 
                new Promise(resolve => 
                    Papa.parse(csvText, { header: true, dynamicTyping: true, complete: results => resolve(results.data) })
                )
            );
    
            const [bbData, cmData, sData] = await Promise.all(parsePromises);
    
            const aggregateData = (data, label) => {
                let dates = data.map(row => {
                    const date = new Date(row.Date_Time_pd_etch);
                    return date.toLocaleDateString();
                });
                let predictions = data.map(row => row.CM_pred || row.BB_pred || row.S_pred);
    
                if (timePeriod === 'Monthly') {
                    const aggregatedData = aggregateByMonth(data);
                    dates = aggregatedData.dates;
                    predictions = aggregatedData.predictions;
                } else if (timePeriod === 'Quarterly') {
                    const aggregatedData = aggregateByQuarter(data);
                    dates = aggregatedData.dates;
                    predictions = aggregatedData.predictions;
                }
    
                return {
                    dates,
                    dataset: {
                        label,
                        data: predictions,
                        fill: false,
                        tension: 0.4,
                        borderColor: label === 'BB Prediction' ? '#42A5F5' : label === 'CM Prediction' ? '#FF6384' : '#18be23',
                    }
                };
            };
    
            const bbResult = aggregateData(bbData, 'BB Prediction');
            const cmResult = aggregateData(cmData, 'CM Prediction');
            const sResult = aggregateData(sData, 'S Prediction');
    
            const dates = bbResult.dates; // Assuming dates are the same for all datasets after aggregation
    
            const data = {
                labels: dates,
                datasets: [bbResult.dataset, cmResult.dataset, sResult.dataset]
            };
    
            setPredictionChartData(data);
            setPredChartOptions(getPredictionChartOptions());
        } catch (error) {
            console.error('Error fetching or parsing CSV data:', error);
        }
    };

    const fetchModelHealthData = async (timePeriod) => {
        try {
            const filePaths = [
                'tenant-1/Total/Total-Health-Data/bb-conf.csv',
                'tenant-1/Total/Total-Health-Data/CM-conf.csv',
                'tenant-1/Total/Total-Health-Data/s-conf.csv'
            ];
    
            const fetchPromises = filePaths.map(filepath =>
                S3Service.downloadFile(filepath).then(blob => blob.text())
            );
    
            const [bbConfText, cmConfText, sConfText] = await Promise.all(fetchPromises);
    
            const parsePromises = [bbConfText, cmConfText, sConfText].map(csvText => 
                new Promise(resolve => 
                    Papa.parse(csvText, { header: true, dynamicTyping: true, complete: results => resolve(results.data) })
                )
            );
    
            const [bbData, cmData, sData] = await Promise.all(parsePromises);
    
            const aggregateData = (data, label) => {
                let dates = data.map(row => {
                    const date = new Date(row.Date_Time_pd_etch);
                    return date.toLocaleDateString();
                });
                let healthData = data.map(row => row.CM_pred || row.BB_pred || row.S_pred);
    
                if (timePeriod === 'Monthly') {
                    const aggregatedData = aggregateByMonth(data);
                    dates = aggregatedData.dates;
                    healthData = aggregatedData.healthData;
                } else if (timePeriod === 'Quarterly') {
                    const aggregatedData = aggregateByQuarter(data);
                    dates = aggregatedData.dates;
                    healthData = aggregatedData.healthData;
                }
    
                return {
                    dates,
                    dataset: {
                        label,
                        data: healthData,
                        fill: false,
                        tension: 0.4,
                        borderColor: label === 'BB Model Health' ? '#42A5F5' : label === 'CM Model Health' ? '#FF6384' : '#18be23',
                    }
                };
            };
    
            const bbResult = aggregateData(bbData, 'BB Model Health');
            const cmResult = aggregateData(cmData, 'CM Model Health');
            const sResult = aggregateData(sData, 'S Model Health');
    
            const dates = bbResult.dates; // Assuming dates are the same for all datasets after aggregation
    
            const data = {
                labels: dates,
                datasets: [bbResult.dataset, cmResult.dataset, sResult.dataset]
            };
    
            setHealthChartData(data);
            setHealthChartOptions(getHealthChartOptions());
        } catch (error) {
            console.error('Error fetching or parsing CSV data:', error);
        }
    };

    const aggregateByMonth = (data) => {
        const aggregatedData = {};
        data.forEach(row => {
            const date = getDateFromRow(row); // Helper function to get date in a consistent format
            const month = `${date.getFullYear()}-${date.getMonth() + 1}`;
            if (!aggregatedData[month]) {
                aggregatedData[month] = {
                    utilization: 0,
                    predictions: 0,
                    healthData: 0,
                    count: 0
                };
            }
            aggregatedData[month].utilization += row.BB_utilization || row.CM_utilization || row.S_utilization || 0;
            aggregatedData[month].predictions += row.BB_pred || row.CM_pred || row.S_pred || 0;
            aggregatedData[month].healthData += row.BB_pred || row.CM_pred || row.S_pred || 0;
            aggregatedData[month].count++;
        });

        const dates = Object.keys(aggregatedData);
        const utilization = dates.map(month => aggregatedData[month].utilization / aggregatedData[month].count);
        const predictions = dates.map(month => aggregatedData[month].predictions / aggregatedData[month].count);
        const healthData = dates.map(month => aggregatedData[month].healthData / aggregatedData[month].count);

        return { dates, utilization, predictions, healthData };
    };

    const getDateFromRow = (row) => {
        if (row.Date) {
            return new Date(row.Date);
        } else if (row.Date_Time_pd_etch) {
            return new Date(row.Date_Time_pd_etch);
        }
        return new Date();
    };

    const aggregateByQuarter = (data) => {
        const aggregatedData = {};

        data.forEach(row => {
            const date = getDateFromRow(row); // Helper function to get date in a consistent format
            const quarter = getQuarter(date);

            if (!aggregatedData[quarter]) {
                aggregatedData[quarter] = {
                    utilization: 0,
                    predictions: 0,
                    healthData: 0,
                    count: 0
                };
            }

            aggregatedData[quarter].utilization += row.BB_utilization || row.CM_utilization || row.S_utilization || 0;
            aggregatedData[quarter].predictions += row.BB_pred || row.CM_pred || row.S_pred || 0;
            aggregatedData[quarter].healthData += row.BB_pred || row.CM_pred || row.S_pred || 0;
            aggregatedData[quarter].count++;
        });

        const quarters = Object.keys(aggregatedData);
        const utilization = quarters.map(quarter => aggregatedData[quarter].utilization / aggregatedData[quarter].count);
        const predictions = quarters.map(quarter => aggregatedData[quarter].predictions / aggregatedData[quarter].count);
        const healthData = quarters.map(quarter => aggregatedData[quarter].healthData / aggregatedData[quarter].count);

        return { dates: quarters, utilization, predictions, healthData };
    };

    // Helper function to get quarter from date
    const getQuarter = (date) => {
        const month = date.getMonth();
        return Math.floor(month / 3) + 1; // Returns 1 for Q1, 2 for Q2, etc.
    };


    const onPageChange = (event) => {
        setFirst(event.first);
    };

    const onRowSelect = (event) => {
        setSelectedRowData(event.data);
    };

    const onStaticTablePageChange = (event) => {
        setFirst2(event.first2);
    };

    const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setSelectedRowData(null);
        }
    };

    const statusBodyTemplate = (rowData) => {
        const statusClassName = rowData.status === 'Active' ? 'status-active' : 'status-complete';
        return (
            <span className={statusClassName}>
                {rowData.status}
            </span>
        );
    };

    const handleDropdownChange = (event) => {
        setSelectedTimePeriod(event.value);
    };

    return (
        <div className="home-container">
            <div className="dropdown-container">
                <Dropdown
                    id="chartDropdown"
                    value={selectedTimePeriod}
                    options={chartDropdown}
                    onChange={handleDropdownChange}
                    placeholder="Select Time Period"
                    optionLabel="label"
                    optionValue="value"
                    className='p-dropdown2'
                />
            </div>
            <div className="charts-section">
                <div className="chart-container chart">
                    <h3 className="chart-title">Model Health</h3>
                    <Chart type="line" data={healthChartData} options={healthChartOptions} />
                </div>
                <div className="chart-container chart">
                    <h3 className="chart-title">Utilization</h3>
                    <Chart type="line" data={utilizationChartData} options={utilChartOptions} />
                </div>
                <div className="chart-container chart">
                    <h3 className="chart-title">Prediction vs Time</h3>
                    <Chart type="line" data={predictionChartData} options={predChartOptions} />
                </div>
            </div>
            <div className="main-table-section">
                <DataTable
                    value={jsonData}
                    className="p-datatable-striped p-datatable-smaller"
                    paginator
                    rows={3}
                    totalRecords={jsonData.length}
                    first={first}
                    onPageChange={onPageChange}
                    onRowSelect={onRowSelect}
                    selectionMode="single"
                    sortField="created_at"
                    sortOrder={-1}
                    onSort={(e) => ('e', e)}
                >
                    <Column field="response_ops" header="Response ops" />
                    <Column field="product" header="Product Code" />
                    <Column field="response_ops" header="Measurement ops" body="210120" />
                    <Column field="model" header="Model" />
                    <Column
                        field="status"
                        header="Status"
                        body={statusBodyTemplate}
                    />
                    <Column field="created_at" header="Date" />
                </DataTable>
                {selectedRowData && (
                    <div className="popup" ref={popupRef}>
                        {/* <p><strong>Prediction:</strong> {selectedRowData.mae}</p> */}
                        <p><strong>Training data size:</strong> 200K</p>
                        <p><strong>Sensitivity:</strong> {selectedRowData.mae_control_range}</p>
                        <p><strong>Data range:</strong> {selectedRowData.response}</p>
                        {/* <p><strong>Operation range:</strong> {selectedRowData.response_ops}</p> */}
                        <p><strong>R2:</strong> {selectedRowData.r_square}</p>
                        <p><strong>MSE:</strong> {selectedRowData.rmse}</p>
                        <p><strong>MAE:</strong> {selectedRowData.mae}</p>
                    </div>
                )}
            </div>
            <div className="static-table-section">
                <h3>Recent Activity</h3>
                <DataTable
                    value={activityLog}
                    className="p-datatable-striped p-datatable-smaller"
                    paginator
                    rows={3}
                    first={first2}
                    onPageChange={onStaticTablePageChange}
                >
                    <Column field="email_id" header="Users" />
                    <Column field="action" header="Actions" />
                    <Column field="timestamp" header="Last Activity" />
                </DataTable>
            </div>
        </div>
    );
};

export default Home;
