import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";

function KnowledgeGraph({
  nodes: initialNodes,
  links: initialLinks,
  showControls = true,
}) {
  const svgRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroups, setSelectedGroups] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [availableGroups, setAvailableGroups] = useState([]);

  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);

  const [nodes, setNodes] = useState(initialNodes);
  const [links, setLinks] = useState(initialLinks);
  const [isAddingNode, setIsAddingNode] = useState(false);
  const [isAddingLink, setIsAddingLink] = useState(false);
  const [linkSourceNode, setLinkSourceNode] = useState(null);
  const [newNodeData, setNewNodeData] = useState({ id: "", group: "" });

  useEffect(() => {
    // Dynamically get available groups from nodes data
    const groups = [...new Set(nodes.map((node) => node.group))];
    setAvailableGroups(groups);
    let projectionLine = null;

    // Initialize selected groups if empty
    if (Object.keys(selectedGroups).length === 0) {
      const initialGroups = groups.reduce(
        (acc, group) => ({
          ...acc,
          [group]: true,
        }),
        {}
      );
      setSelectedGroups(initialGroups);
      return;
    }

    // Filter nodes based on search and group filters
    const filteredNodes = nodes.filter((node) => {
      const matchesSearch = node.id
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const groupIsSelected = selectedGroups[node.group];
      return matchesSearch && groupIsSelected;
    });

    // Filter links to only include connections between filtered nodes
    const filteredLinks = links.filter((link) => {
      const sourceExists = filteredNodes.find(
        (node) => node.id === link.source
      );
      const targetExists = filteredNodes.find(
        (node) => node.id === link.target
      );
      return sourceExists && targetExists;
    });

    const filteredData = {
      nodes: filteredNodes.map((node) => ({ ...node })),
      links: filteredLinks.map((link) => ({ ...link })),
    };

    const svg = d3.select(svgRef.current);

    // Add click handler to svg to clear selection
    svg.on("click", () => {
      setSelectedNode(null);
      setSelectedLink(null);
    });
    svg.selectAll("*").remove(); // Clear previous content

    const width = svgRef.current.clientWidth || 800;
    const height = svgRef.current.clientHeight || 600;

    // Add zoom and pan functionality
    const zoom = d3
      .zoom()
      .scaleExtent([0.5, 5])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      });
    svg.call(zoom);

    // Create a container group
    const g = svg.append("g");

    // Define color scale
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    // Create simulation
    const simulation = d3
      .forceSimulation(filteredData.nodes)
      .force(
        "link",
        d3
          .forceLink(filteredData.links)
          .id((d) => d.id)
          .distance(150)
      )
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // Draw links
    const link = g
      .append("g")
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
      .data(filteredData.links)
      .enter()
      .append("line")
      .attr("stroke-width", 2)
      .on("click", (event, d) => {
        event.stopPropagation();
        setSelectedLink(d);
        setSelectedNode(null); // Clear selected node
      })
      // Add these style updates
      .style("stroke", (d) => (d === selectedLink ? "#ff0000" : "#999"))
      .style("stroke-width", (d) => (d === selectedLink ? 4 : 2))
      .on("contextmenu", (event, d) => {
        event.preventDefault();
        // Delete link on right-click
        setLinks((prevLinks) => prevLinks.filter((l) => l !== d));
      });

    // Draw nodes
    const node = g
      .append("g")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1.5)
      .selectAll("circle")
      .data(filteredData.nodes, (d) => d.id)
      .enter()
      .append("circle")
      .attr("r", 10)
      .attr("fill", (d) => color(d.group))
      .call(
        d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      )
      .on("mouseover", (event, d) => {
        tooltip.transition().duration(200).style("opacity", 0.9);
        tooltip
          .html(`${d.id}<br/>(${d.group})`)
          .style("left", event.pageX + 10 + "px")
          .style("top", event.pageY - 28 + "px");
      })
      .on("mouseout", () => {
        tooltip.transition().duration(500).style("opacity", 0);
      })
      .on("click", (event, d) => {
        if (isAddingLink) {
          if (!linkSourceNode) {
            // First node selected for link
            setLinkSourceNode(d.id);
          } else {
            // Second node selected, create link
            const newLink = {
              source: linkSourceNode,
              target: d.id,
              relation: "related",
            };
            setLinks((prevLinks) => [...prevLinks, newLink]);
            setLinkSourceNode(null);
            setIsAddingLink(false);
          }
        } else {
          // Zoom into node
          event.stopPropagation();

          setSelectedNode(d.id); // Add this line
          setSelectedLink(null); // Clear selected link
          const transform = d3.zoomIdentity
            .translate(width / 2, height / 2)
            .scale(2)
            .translate(-d.x, -d.y);

          svg.transition().duration(750).call(zoom.transform, transform);
        }
      })
      .on("contextmenu", (event, d) => {
        event.preventDefault();
        // Delete node and associated links on right-click
        setNodes((prevNodes) => prevNodes.filter((node) => node !== d));
        setLinks((prevLinks) =>
          prevLinks.filter(
            (link) => link.source !== d.id && link.target !== d.id
          )
        );
      });

    if (isAddingLink && linkSourceNode) {
      const sourceNode = filteredData.nodes.find(
        (node) => node.id === linkSourceNode
      );

      if (sourceNode) {
        // Initialize projection line
        projectionLine = g
          .append("line")
          .attr("class", "projection-line")
          .attr("stroke", "#000")
          .attr("stroke-width", 2)
          .attr("stroke-dasharray", "5,5")
          .attr("x1", sourceNode.x)
          .attr("y1", sourceNode.y)
          .attr("x2", sourceNode.x)
          .attr("y2", sourceNode.y);

        // Function to update projection line on mousemove
        const mousemove = (event) => {
          const point = d3.pointer(event, g.node());
          const [x, y] = point;

          projectionLine.attr("x2", x).attr("y2", y);
        };

        svg.on("mousemove", mousemove);
      }
    }

    // Add labels
    const label = g
      .append("g")
      .selectAll("text")
      .data(filteredData.nodes)
      .enter()
      .append("text")
      .attr("dy", -15)
      .attr("text-anchor", "middle")
      .text((d) => d.id)
      .style("font-size", "12px");

    // Add tooltips
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("position", "absolute")
      .style("text-align", "center")
      .style("padding", "6px")
      .style("font", "12px sans-serif")
      .style("background", "lightsteelblue")
      .style("border", "0px")
      .style("border-radius", "8px")
      .style("pointer-events", "none")
      .style("opacity", 0);

    // Reset zoom on double click
    svg.on("dblclick", () => {
      svg.transition().duration(750).call(zoom.transform, d3.zoomIdentity);
    });

    // Define simulation tick behavior
    simulation.on("tick", () => {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);

      label.attr("x", (d) => d.x).attr("y", (d) => d.y);
    });

    function getNodeById(id) {
      if (typeof id === "object") return id; // If id is already a node, return it
      return filteredData.nodes.find((node) => node.id === id);
    }

    // Drag functions
    function dragstarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragended(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    // Cleanup on unmount
    return () => {
      simulation.stop();
      tooltip.remove();

      // Remove projection line and mousemove handler if they exist
      if (projectionLine) {
        projectionLine.remove();
      }

      svg.on("mousemove", null);
    };
  }, [
    searchTerm,
    selectedGroups,
    nodes,
    links,
    isAddingLink,
    linkSourceNode,
    selectedNode,
    selectedLink,
  ]); // Re-run effect when data or filters change

  const handleGroupToggle = (group) => {
    setSelectedGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  const handleAddNode = () => {
    setIsAddingNode(true);
  };

  const handleNodeSubmit = () => {
    if (newNodeData.id && newNodeData.group) {
      setNodes((prevNodes) => [...prevNodes, { ...newNodeData }]);
      setNewNodeData({ id: "", group: "" });
      setIsAddingNode(false);
    }
  };

  const handleAddLink = () => {
    setIsAddingLink(true);
    setLinkSourceNode(null);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        if (selectedNode) {
          setNodes((prevNodes) =>
            prevNodes.filter((node) => node.id !== selectedNode)
          );
          setLinks((prevLinks) =>
            prevLinks.filter(
              (link) =>
                link.source !== selectedNode && link.target !== selectedNode
            )
          );
          setSelectedNode(null);
        }
        if (selectedLink) {
          setLinks((prevLinks) =>
            prevLinks.filter((link) => link !== selectedLink)
          );
          setSelectedLink(null);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [selectedNode, selectedLink]);

  return (
    <div className="relative h-[calc(100vh-6rem)]">
      {/* SVG Visualization */}
      <svg
        ref={svgRef}
        className="w-full h-full rounded-2xl border border-gray-200 bg-white"
      ></svg>

      {/* Controls overlay */}
      {showControls && (
        <div className="absolute top-6 right-6 z-10 w-96">
          <div className="bg-white/80 backdrop-blur-lg rounded-2xl shadow-sm border border-gray-100 p-6">
            {/* Search Input */}
            <div className="mb-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search nodes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full h-11 pl-4 pr-10 rounded-xl border border-gray-200 bg-gray-50/50 text-sm text-gray-900 placeholder-gray-500 outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20 transition duration-200"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-4">
              {/* Filter Dropdown */}
              <div className="relative">
                <button
                  onClick={() => setIsFilterOpen(!isFilterOpen)}
                  className="px-4 py-2 text-sm font-medium bg-gray-100 text-gray-700 rounded-xl hover:bg-gray-200 transition-all duration-200 flex items-center gap-2"
                >
                  <span>Filters</span>
                  <svg
                    className={`w-4 h-4 transition-transform duration-200 ${
                      isFilterOpen ? "rotate-180" : ""
                    }`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>
                {isFilterOpen && (
                  <div className="absolute top-full left-0 mt-2 w-48 bg-white rounded-xl shadow-lg border border-gray-100 p-2">
                    {availableGroups.map((group) => (
                      <button
                        key={group}
                        onClick={() => handleGroupToggle(group)}
                        className={`
                        w-full px-4 py-2 text-sm font-medium text-left rounded-lg transition-all duration-200 mb-1 last:mb-0
                        ${
                          selectedGroups[group]
                            ? "bg-blue-100 text-blue-600 hover:bg-blue-200"
                            : "hover:bg-gray-100 text-gray-600"
                        }
                      `}
                      >
                        {group}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              {/* Action Buttons */}
              <button
                onClick={handleAddNode}
                className="px-4 py-2 bg-green-500 text-white rounded-xl hover:bg-green-600 transition"
              >
                Add Node
              </button>
              <button
                onClick={handleAddLink}
                className={`px-4 py-2 rounded-xl transition ${
                  isAddingLink
                    ? "bg-red-500 text-white hover:bg-red-600"
                    : "bg-blue-500 text-white hover:bg-blue-600"
                }`}
              >
                {isAddingLink ? "Cancel Adding Link" : "Add Link"}
              </button>
              {isAddingLink && linkSourceNode && (
                <span className="text-sm text-gray-700">
                  Select target node to complete the link
                </span>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Add Node Modal */}
      {isAddingNode && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-xl shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Add New Node</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Node ID
              </label>
              <input
                type="text"
                value={newNodeData.id}
                onChange={(e) =>
                  setNewNodeData({ ...newNodeData, id: e.target.value })
                }
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Group
              </label>
              <input
                type="text"
                value={newNodeData.group}
                onChange={(e) =>
                  setNewNodeData({ ...newNodeData, group: e.target.value })
                }
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setIsAddingNode(false)}
                className="px-4 py-2 bg-gray-500 text-white rounded-xl hover:bg-gray-600 transition"
              >
                Cancel
              </button>
              <button
                onClick={handleNodeSubmit}
                className="px-4 py-2 bg-green-500 text-white rounded-xl hover:bg-green-600 transition"
              >
                Add Node
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
        <div className="px-4 py-2 bg-gray-900/80 backdrop-blur rounded-full">
          <span className="text-xs font-medium text-white">
            Double click to reset zoom
          </span>
        </div>
      </div>
    </div>
  );
}

export default KnowledgeGraph;
