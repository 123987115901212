import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ActivityService from '../../service/ActivityService';

const Logout = () => {
    const navigate = useNavigate();

    const logActivity = async (action, userId, email) => {
        try {
            const activityLog = {
                email_id: email,
                action: action
            };
            await ActivityService.addActivityLog(activityLog, userId);
        } catch (error) {
            console.error('Failed to log activity:', error);
        }
    };

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            logActivity('User logged out', user?.data?.id, user?.data?.email);
        }
        localStorage.removeItem('token');
        sessionStorage.clear();
        localStorage.removeItem('user');

        navigate('/');
    }, [navigate]);

    return (
        <div>
        </div>
    );
};

export default Logout;
