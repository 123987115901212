import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import UserService from '../../service/UserService';
import ActivityService from '../../service/ActivityService';
import { useUser } from '../../context/userContext';
import './login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const { loginUser } = useUser();

    useEffect(() => {
        const savedEmail = localStorage.getItem('rememberedEmail');
        if (savedEmail) {
            setEmail(savedEmail);
            setRememberMe(true);
        }
    }, []);

    const logActivity = async (action, userId, email) => {
        try {
            const activityLog =
            {
                email_id: email,
                action: action
            }
            await ActivityService.addActivityLog(activityLog, userId);
        } catch (error) {
            console.error('Failed to log activity:', error);
        }
    };

    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        try {
            const userData = await UserService.login(email, password);
            if (userData) {
                if (userData?.data?.is_approved !== true) {
                    setMessage('Your account is pending for approval by the admin.');
                } else {
                    loginUser(userData);
                    logActivity('User logged in', userData?.data?.id, userData?.data?.email);
                    if (rememberMe) {
                        localStorage.setItem('rememberedEmail', email);
                    } else {
                        localStorage.removeItem('rememberedEmail');
                    }
                    navigate('/portal/home');
                }
            } else {
                setMessage('Username or password is incorrect');
            }
        } catch (error) {
            setMessage('Unsuccessful login. Please try again.');
        }
    };

    const handleSignUpSubmit = async (event) => {
        event.preventDefault();
        try {
            const userData = await UserService.register(name, email, password);
            console.log("userData",userData)
            if (userData) {
                setMessage('Registration successful! Your account is pending for approval.');
                await logActivity('User registered', userData?.data?.id, userData?.data?.email);
            } else {
                setMessage('Unsuccessful registration. Please speak to Sales team.');
            }
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setMessage('Unsuccessful registration. User already exists, Please try again.');
            } else {
                setMessage('Unsuccessful registration. Please try again.');
            }
        }
    };

    return (
        <div className="login-container">
            <Card className="login-card">
                <div className="login-flex-container">
                    <div className="login-image">
                        <div className="image-overlay">
                            {/* <h2>Welcome to TIA Studio</h2> */}
                        </div>
                        <img
                            src="/asset/data/Group-trans.png"
                            alt="Login"
                            style={{ width: '85%', height: 'auto' }}
                        />
                    </div>
                    <div className="login-form">
                        <div className="login-options">
                            {/* <Button
                                label="Sign In"
                                className={isLogin ? 'active' : ''}
                                onClick={() => { setIsLogin(true); setMessage(''); }}
                            /> */}
                            {/* <Button
                                label="Sign Up"
                                className={!isLogin ? 'active' : ''}
                                onClick={() => { setIsLogin(false); setMessage(''); }}
                            /> */}
                        </div>
                        {message && <p className="message">{message}</p>}
                        {isLogin ? (
                            <form className="active" onSubmit={handleLoginSubmit}>
                                <h2>Sign In</h2>
                                <div className="field">
                                    <label htmlFor="email">Email:</label>
                                    <InputText
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="password">Password:</label>
                                    <Password
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        toggleMask
                                        feedback={false}
                                        required
                                        className="password-input"
                                    />
                                </div>
                                <div className="rememberMe">
                                    <Checkbox inputId="rememberMe" name="rememberMe" onChange={(e) => setRememberMe(e.target.checked)} checked={rememberMe} />
                                    <label htmlFor="rememberMe">Remember Me</label>
                                </div>
                                <div className='forgot-password'>
                                    <a href="/forgot-password" className="forgot-password-link">
                                        Forgot Password?
                                    </a>
                                </div>
                                <Button label="Login" type="submit" />
                            </form>
                        ) : (
                            <form className="signup-form active" onSubmit={handleSignUpSubmit}>
                                <h2>Sign Up</h2>
                                <div className="field">
                                    <label htmlFor="name">Name:</label>
                                    <InputText
                                        id="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="signup-email">Email:</label>
                                    <InputText
                                        id="signup-email"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="field">
                                    <label htmlFor="signup-password">Password:</label>
                                    <Password
                                        id="signup-password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        toggleMask
                                        feedback={false}
                                        required
                                        className="password-input"
                                    />
                                </div>
                                <Button label="Register" type="submit" disabled  />
                            </form>
                        )}
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default Login;
