import React, { useState, useEffect, forwardRef, useImperativeHandle,useRef } from 'react';
import { Chart } from 'primereact/chart';
import GraphLayout from '../graphLayout';
import html2canvas from 'html2canvas';
import Papa from 'papaparse';

const Histogram = forwardRef(({ selectedResponse, summaryData, csvData }, ref) => {
    const [data, setData] = useState({ labels: [], datasets: [] });
    const [chartOptions, setChartOptions] = useState({});
    const summaryChartRef = useRef(null);

    useEffect(() => {
        if (csvData.length > 0) {
            processCsvData(csvData);
        }
    }, [csvData]);

    useImperativeHandle(ref, () => ({
        // captureScreenshot: () => {
        //     return screenshotRef.current;
        // },
        captureSummaryChartScreenshot: () => {
            return summaryChartRef.current;
        }
    }));

    const processCsvData = (data) => {
        const labels = data.map(row => {
            const date = new Date(row.Date_Time_pd_etch);
            return date.toLocaleDateString();
        });
        const values = data.map(row => row.BB_pred);

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Model Health',
                    data: values,
                    fill: false,
                    tension: 0.4,
                    borderColor: '#42A5F5',
                }
            ]
        };

        setData(chartData);
        setChartOptions(getChartOptions());
    };

    const getChartOptions = () => {
        return {
            maintainAspectRatio: false,
            aspectRatio: 1.5,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Time',
                    },
                },
                y: {
                    grid: {
                        display: true,
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Confidence score (CS)',
                    },
                },
            },
        };
    };

    // Commented out code for future use
    /*
    const labels = ['MAE', 'RMSE', 'R Square', 'MAE Control Range'];
    const values = selectedResponse
        ? [selectedResponse.mae, selectedResponse.rmse, selectedResponse.r_square, selectedResponse.mae_control_range]
        : [0, 0, 0, 0];

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Histogram Data Points',
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
                data: values,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    */

    return (
        <GraphLayout 
            title="Model Health"
            chartComponent={<Chart type="line" data={data} options={chartOptions} />}
            selectedResponse={selectedResponse}
            summaryData={summaryData}
            summaryChartRef={summaryChartRef}
        />
    );
});

export default Histogram;
