import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import './csvDataTable.css';

const CsvDataTable = ({ headers, data, rowsPerPageOptions = [10, 20, 30], initialRows = 10 }) => {
    const [firstRow, setFirstRow] = useState(0);
    const [rows, setRows] = useState(initialRows);
    const [showAllColumns, setShowAllColumns] = useState(false);
    const [filteredData, setFilteredData] = useState(data);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        applyFilters(filters);
    }, [data, filters]);

    const onRowPageChange = (event) => {
        setFirstRow(event.first);
        setRows(event.rows);
    };

    const clearFilter = (field, filterElement) => {
        const updatedFilters = { ...filters };
        delete updatedFilters[field];  // Remove the filter for the specified field
        setFilters(updatedFilters);    // Update the state with the new filters

        // Programmatically close the filter popup
        if (filterElement) {
            const filterPopup = filterElement.closest('.p-column-filter-overlay');
            if (filterPopup) {
                filterPopup.style.display = 'none';
            }
        }
    };

    const filterClearTemplate = (options) => {
        return (
            <Button
                type="button"
                icon="pi pi-times"
                onClick={() => {
                    clearFilter(options.field, options.element);
                }}
                severity="secondary"
            />
        );
    };

    const onFilterChange = (event) => {
        const field = event.field;
        const value = event.constraints.constraints[0].value;
        const matchMode = event.constraints.constraints[0].matchMode;

        const newFilters = { ...filters };
        if (matchMode === null || value === '' || value === null) {
            delete newFilters[field];
        } else {
            newFilters[field] = { value, matchMode };
        }

        setFilters(newFilters);
    };

    const applyFilters = (filterObj) => {
        if (Object.keys(filterObj).length === 0) {
            setFilteredData(data);
        } else {
            let filtered = data;

            Object.keys(filterObj).forEach((field) => {
                if (filterObj[field]) {
                    const { value, matchMode } = filterObj[field];
                    if (!matchMode) return; // Skip if matchMode is undefined
                    filtered = filtered.filter((item) => {
                        const itemValue = item[field];
                        if (itemValue === undefined || itemValue === null) {
                            return false;
                        }
                        const itemString = itemValue?.toString().toLowerCase();
                        const filterValue = value?.toString().toLowerCase();

                        let match = false;
                        switch (matchMode) {
                            case 'startsWith':
                                match = itemString.startsWith(filterValue);
                                break;
                            case 'endsWith':
                                match = itemString.endsWith(filterValue);
                                break;
                            case 'contains':
                                match = itemString.includes(filterValue);
                                break;
                            case 'notContains':
                                match = !itemString.includes(filterValue);
                                break;
                            case 'equals':
                                match = itemString === filterValue;
                                break;
                            case 'notEquals':
                                match = itemString !== filterValue;
                                break;
                            default:
                                match = itemString.includes(filterValue); // Default behavior
                        }
                        return match;
                    });
                }
            });

            setFilteredData(filtered);
        }
        setFirstRow(0); // Reset to the first page on filter change
    };

    const getSlicedData = () => {
        const start = Math.max(firstRow, 0);
        const end = Math.min(start + rows, filteredData.length);
        return filteredData.slice(start, end);
    };

    return (
        <div className="csv-data-table-container">
            <div className="card-header">
                <button onClick={() => setShowAllColumns(!showAllColumns)} className="toggle-columns-button">
                    {showAllColumns ? 'Show Less Columns' : 'Show More Columns'}
                </button>
                <div className="row-paginator">
                    <Paginator
                        first={firstRow}
                        rows={rows}
                        totalRecords={filteredData.length}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onPageChange={onRowPageChange}
                    />
                </div>
            </div>
            <div className="csv-data-table">
                <DataTable
                    key={filteredData.length} // Force re-render on filteredData change
                    value={getSlicedData()}
                    responsiveLayout="scroll"
                    columnResizeMode="expand"
                    resizableColumns
                    showGridlines
                    sortMode="multiple"
                    removableSort
                    paginator={false} // Disable built-in paginator
                >
                    {headers.slice(0, showAllColumns ? headers.length : 8).map((header, index) => (
                        <Column
                            key={index}
                            field={header}
                            header={header}
                            sortable
                            resizable
                            filter
                            filterMatchModeOptions={[
                                { label: 'No Filter', value: null },
                                { label: 'Starts With', value: 'startsWith' },
                                { label: 'Ends With', value: 'endsWith' },
                                { label: 'Contains', value: 'contains' },
                                { label: 'Not Contains', value: 'notContains' },
                                { label: 'Equals', value: 'equals' },
                                { label: 'Not Equals', value: 'notEquals' }
                            ]}
                            filterPlaceholder={`Search by ${header}`}
                            filterField={header}
                            onFilterApplyClick={onFilterChange}
                            filterClear={filterClearTemplate}
                        />
                    ))}
                </DataTable>
            </div>
        </div>
    );
};

export default CsvDataTable;
