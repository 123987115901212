import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import GraphLayout from '../graphLayout';
import Papa from 'papaparse';

const BoxPlot = ({ selectedResponse, summaryData }) => {

    const [data, setData] = useState({ labels: [], datasets: [] });
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        fetchBoxData();
    }, []);

    const getChartOptions = () => {
        return {
            maintainAspectRatio: false,
            aspectRatio: 1.5,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Time',
                    },
                },
                y: {
                    grid: {
                        display: true,
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Prediction',
                    },
                },
            },
        };
    };


    const fetchBoxData = () => {
        fetch('/csv-data/job-specific/prediction.csv')
            .then(response => response.text())
            .then(csvText => {
                Papa.parse(csvText, {
                    header: true,
                    dynamicTyping: true,
                    complete: (results) => {
                        const labels = results.data.map(row => {
                            const date = new Date(row.Date_Time_pd_etch);
                            return date.toLocaleDateString();
                        });
                        const values = results.data.map(row => row.BB_pred);

                        const chartData = {
                            labels: labels,
                            datasets: [
                                {
                                    label: 'Prediction',
                                    data: values,
                                    fill: false,
                                    tension: 0.4,
                                    borderColor: '#FFA726',
                                    backgroundColor: 'rgba(255,167,38,0.2)',
                                }
                            ]
                        };

                        setData(chartData);
                        setChartOptions(getChartOptions());
                    }
                });
            })
            .catch(error => console.error('Error fetching CSV data:', error));
    }

    // const labels = ['MAE', 'RMSE', 'R Square', 'MAE Control Range'];
    // const datasets = [
    //     {
    //         label: 'Box Plot Data',
    //         backgroundColor: 'rgba(54, 162, 235, 0.2)',
    //         borderColor: 'rgba(54, 162, 235, 1)',
    //         borderWidth: 1,
    //         data: [
    //             selectedResponse?.mae,
    //             selectedResponse?.rmse,
    //             selectedResponse?.r_square,
    //             selectedResponse?.mae_control_range,
    //         ],
    //     },
    // ];

    // const options = {
    //     indexAxis: 'y',
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     scales: {
    //         x: {
    //             title: {
    //                 display: true,
    //                 text: 'Value',
    //             },
    //         },
    //     },
    // };

    return (
        <GraphLayout
            title="Prediction vs Time"
            chartComponent={<Chart type="line" data={data} options={chartOptions} />}
            selectedResponse={selectedResponse}
            summaryData={summaryData}
        />
    );
};

export default BoxPlot;
