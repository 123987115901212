import React, { forwardRef, useRef } from 'react';
import { Chart } from 'primereact/chart';
import './plot.css';

const GraphLayout = forwardRef(({ title, chartComponent, summaryData, summaryChartRef }, ref) => {
    // Sort the summaryData in decreasing order based on value
    const sortedSummaryData = [...summaryData].sort((a, b) => b.value - a.value);

    const summaryLabels = sortedSummaryData.map(data => data.label.replace(/'/g, '').trim());
    const summaryValues = sortedSummaryData.map(data => data.value);

    const data = {
        labels: summaryLabels,
        datasets: [
            {
                label: 'Summary',
                backgroundColor: 'rgba(255, 159, 64, 0.2)',
                borderColor: 'rgba(255, 159, 64, 1)',
                borderWidth: 1,
                data: summaryValues,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    precision: 3,
                    minTicksLimit: 5,
                },
            },
        },
    };

    return (
        <div ref={ref} className="plot-container">
            <div className="section">
                <h3 className="section-title">{title}</h3>
                <div className="chart-container chart">
                    {chartComponent}
                </div>
            </div>
            <div className="section summary">
                <div ref={summaryChartRef} className="bar-chart">
                    <h3 className="section-title">Summary</h3>
                    {summaryData.length > 0 ? (
                        <div className="bar-chart">
                            <Chart type="bar" data={data} options={options} />
                        </div>
                    ) : (
                        <p>No data available</p>
                    )}
                </div>
            </div>
        </div>
    );
});

export default GraphLayout;
