import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { useNavigate } from "react-router-dom";
import "./tabLayout.css";

const TabLayout = ({ children, activeIndex, setActiveIndex }) => {
  const navigate = useNavigate();

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
    if (e.index === 0) {
      navigate("/portal/reports");
    } else if (e.index === 1) {
      navigate("/portal/knowledge-base");
    } else if (e.index === 2) {
      navigate("/portal/documents-used");
    }
  };

  return (
    <TabView
      className="h-full p-0"
      activeIndex={activeIndex}
      onTabChange={handleTabChange}
    >
      <TabPanel className="h-full p-0" header="Report">
        {children}
      </TabPanel>
      <TabPanel header="Knowledge Base">{children}</TabPanel>
      <TabPanel header="Documents Used">{children}</TabPanel>
    </TabView>
  );
};

export default TabLayout;
