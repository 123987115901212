import ReactMarkdown from "react-markdown";
import ConfidenceIndicator from "./confidence-indicator";
import FeedbackPanel from "./feedback-panel";
import MessageActions from "./message-actions";
import { useState, useRef, useEffect } from "react";

const BotMessage = ({
  entry,
  feedbackPanel,
  onCopy,
  onFeedback,
  onFeedbackSelection,
  onCloseFeedback,
}) => {
  const showFeedbackPanel =
    feedbackPanel.isOpen && feedbackPanel.messageId === entry.id;

  return (
    <div className="relative group p-3 rounded-xl bg-[#e2e3e5] self-start rounded-tr-none rounded-bl-none max-w-[70%]">
      <div className="relative">
        <ReactMarkdown className="prose max-w-none">{entry.text}</ReactMarkdown>

        {entry.references && entry.references.length > 0 && (
          <MessageMetadata
            references={entry.references}
            cscore={entry.cscore}
          />
        )}

        <div className="absolute bottom-0 right-0 mb-[-40px] mr-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
          <MessageActions
            onCopy={onCopy}
            onFeedback={onFeedback}
            feedbackPanel={feedbackPanel}
            messageId={entry.id}
            text={entry.text}
          />
        </div>

        {showFeedbackPanel && (
          <FeedbackPanel
            type={feedbackPanel.type}
            onClose={onCloseFeedback}
            onFeedbackSelection={onFeedbackSelection}
          />
        )}
      </div>
    </div>
  );
};

const MessageMetadata = ({ references, cscore }) => (
  <div className="mt-2 flex items-center justify-between">
    <div className="relative flex items-center">
      <References references={references} />
      <span className="text-gray-600 text-xs mr-2">Confidence:</span>
      <span className="text-xs text-gray-700 mr-2 font-semibold">{cscore}</span>
      <ConfidenceIndicator score={cscore} />
    </div>
  </div>
);

const References = ({ references }) => {
  const [showRefs, setShowRefs] = useState(false);
  const refsRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        refsRef.current &&
        !refsRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowRefs(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (target) => {
    setShowRefs(true);
  };

  const handleMouseLeave = (e) => {
    const toElement = e.relatedTarget;
    if (
      !refsRef.current?.contains(toElement) &&
      !buttonRef.current?.contains(toElement)
    ) {
      setShowRefs(false);
    }
  };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        className="text-blue-600 text-xs bg-transparent hover:underline focus:outline-none mr-4"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        References ({references.length})
      </button>
      {showRefs && (
        <div
          ref={refsRef}
          className="absolute bg-white border border-gray-300 p-2 shadow-md z-10 top-full left-0 w-[300px] whitespace-normal"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {references.map((ref, refIndex) => (
            <div key={refIndex} className="mb-1">
              <a
                href={ref}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 text-xs no-underline hover:underline break-all"
              >
                {ref}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BotMessage;
