import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";

const ReportSettings = ({ reports, onUpdateReports, onShowToast }) => {
  const { reportId } = useParams();
  const [report, setReport] = useState(null);
  const [settings, setSettings] = useState({
    tone: "neutral",
    systemInstructions: "",
  });

  useEffect(() => {
    const selectedReport = reports.find((r) => r.id === reportId);
    if (selectedReport) {
      setReport(selectedReport);
      setSettings(selectedReport.settings || settings);
    }
  }, [reportId, reports]);

  const handleSaveSettings = () => {
    if (!report) return;
    const updatedReport = {
      ...report,
      settings,
      updatedAt: new Date().toISOString(),
    };
    const updatedReports = reports.map((r) =>
      r.id === report.id ? updatedReport : r
    );
    onUpdateReports(updatedReports);
    onShowToast(
      "success",
      "Settings saved",
      "Your report settings have been updated."
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  if (!report) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">
          Select a report to configure its settings.
        </p>
      </div>
    );
  }

  return (
    <div className="col-span-3 w-full p-6 bg-white overflow-y-auto h-full mx-auto">
      <h1 className="text-2xl font-bold mb-8">Report Settings</h1>
      <div className="space-y-8">
        {/* Tone setting */}
        <div>
          <label
            htmlFor="tone"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Tone
          </label>
          <div className="relative">
            <select
              name="tone"
              value={settings.tone}
              onChange={handleChange}
              className="appearance-none w-full bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="neutral">Neutral</option>
              <option value="formal">Formal</option>
              <option value="informal">Informal</option>
              <option value="friendly">Friendly</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <FiChevronDown className="h-4 w-4" />
            </div>
          </div>
        </div>

        {/* System Instructions */}
        <div>
          <label
            htmlFor="systemInstructions"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            System Instructions
          </label>
          <textarea
            name="systemInstructions"
            value={settings.systemInstructions}
            onChange={handleChange}
            className="w-full border border-gray-300 border-solid rounded-md py-2 px-3 text-sm leading-5 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
            rows="6"
            placeholder="Enter system instructions here..."
          ></textarea>
        </div>
      </div>
      <div className="mt-8">
        <button
          onClick={handleSaveSettings}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save changes
        </button>
      </div>
    </div>
  );
};

export default ReportSettings;
