import React, { useMemo } from "react";
import { FaTable } from "react-icons/fa";

const TablesUsedComponent = React.memo(({ data, isLoading }) => {
  const parseCSV = (csvData) => {
    if (!csvData) return [];
    const rows = csvData.split("\n");
    return rows.map((row) => row.split(",").map((cell) => cell.trim()));
  };

  const renderContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="animate-pulse flex flex-col space-y-4 max-w-md mx-auto h-64">
          <div className="h-8 bg-slate-200 rounded w-1/4"></div>
          <div className="flex-1 bg-slate-200 rounded"></div>
          <div className="h-4 bg-slate-200 rounded w-full"></div>
        </div>
      );
    }

    if (!data || !Array.isArray(data) || data.length === 0) {
      return (
        <div className="text-gray-500 text-center py-8">
          No table data available
        </div>
      );
    }

    return (
      <div className="space-y-8">
        {data.map((table, tableIndex) => {
          const parsedData = parseCSV(table.content);
          if (parsedData.length === 0) return null;

          const headers = parsedData[0];
          const rows = parsedData.slice(1);

          return (
            <div key={tableIndex} className="bg-white shadow-md rounded-lg p-4">
              <div className="flex items-center gap-2 mb-4">
                <FaTable className="text-gray-600" />
                <h4 className="text-md font-semibold">{table.name}</h4>
              </div>

              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      {headers.map((header, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, colIndex) => (
                          <td
                            key={`${rowIndex}-${colIndex}`}
                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    );
  }, [data, isLoading]);

  return renderContent;
});

export default TablesUsedComponent;
