import React, { useState, useRef, useEffect } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { FaTag, FaChevronDown, FaPlus, FaTimes } from "react-icons/fa";

const LabelSelector = ({ labels, allLabels, onLabelsChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isCreatingLabel, setIsCreatingLabel] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  const filteredLabels = allLabels.filter((label) =>
    label.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLabelToggle = (label) => {
    const updatedLabels = labels.some((l) => l.text === label.text)
      ? labels.filter((l) => l.text !== label.text)
      : [...labels, label];
    onLabelsChange(updatedLabels);
  };

  const handleCreateLabel = () => {
    setIsCreatingLabel(true);
  };

  const handleColorSelect = (color) => {
    const newLabel = { text: searchTerm, color };
    onLabelsChange([...labels, newLabel]);
    setIsCreatingLabel(false);
    setSearchTerm("");
  };

  const colors = [
    "#6B7280", // gray
    "#EF4444", // red
    "#F59E0B", // yellow
    "#10B981", // green
    "#3B82F6", // blue
    "#6366F1", // indigo
    "#8B5CF6", // purple
    "#EC4899", // pink
  ];

  return (
    <div className="relative inline-block text-left mr-2">
      <Popover>
        {({ open, close }) => (
          <>
            <PopoverButton className="flex items-center text-gray-700 bg-transparent hover:bg-gray-100 rounded p-1 text-sm">
              <FaTag className="h-4 w-4 mr-2" />
              <span>
                {labels.length
                  ? `${labels.length} label${labels.length > 1 ? "s" : ""}`
                  : "Add labels"}
              </span>
              <FaChevronDown className="h-3 w-3 ml-2" />
            </PopoverButton>

            {open && (
              <PopoverPanel
                static
                className="absolute z-10 mt-1 w-64 max-h-80 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none right-0"
              >
                <div className="p-2">
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Search labels..."
                    className="w-full px-3 py-2 text-sm leading-5 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="py-1">
                  {filteredLabels.map((label) => (
                    <button
                      key={label.text}
                      className="flex items-center w-full bg-transparent px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={() => handleLabelToggle(label)}
                    >
                      <div
                        style={{ backgroundColor: label.color }}
                        className="w-4 h-4 rounded-full mr-3"
                      />
                      {label.text}
                      {labels.some((l) => l.text === label.text) && (
                        <span className="ml-auto">✓</span>
                      )}
                    </button>
                  ))}
                  {searchTerm && !filteredLabels.length && !isCreatingLabel && (
                    <button
                      className="flex items-center w-full bg-transparent px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      onClick={handleCreateLabel}
                    >
                      <FaPlus className="h-4 w-4 mr-3" />
                      Create new label "{searchTerm}"
                    </button>
                  )}
                  {isCreatingLabel && (
                    <div className="px-4 py-2">
                      <p className="text-sm text-gray-700 mb-2">
                        Pick a color for label
                      </p>
                      <div className="flex flex-wrap gap-2">
                        {colors.map((color) => (
                          <button
                            key={color}
                            style={{ backgroundColor: color }}
                            className="w-6 h-6 rounded-full hover:ring-2 ring-offset-2"
                            onClick={() => handleColorSelect(color)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </PopoverPanel>
            )}
          </>
        )}
      </Popover>
    </div>
  );
};

export default LabelSelector;
