import { FaClipboard, FaRedo, FaThumbsUp, FaThumbsDown } from "react-icons/fa";

const MessageActions = ({
  onCopy,
  onFeedback,
  feedbackPanel,
  messageId,
  text,
}) => (
  <div className="flex space-x-2 bg-white rounded-md shadow-md p-1">
    <button
      onClick={() => onCopy(text)}
      className="flex items-center bg-white text-gray-700 p-1 rounded hover:bg-gray-100 transition-colors duration-200"
    >
      <FaClipboard className="w-3 h-3 mr-1 stroke-current" />
      <span className="text-xs">Copy</span>
    </button>
    <button className="flex items-center bg-white text-gray-700 p-1 rounded hover:bg-gray-100 transition-colors duration-200">
      <FaRedo className="w-3 h-3 mr-1 stroke-current" />
      <span className="text-xs">Retry</span>
    </button>
    {["like", "dislike"].map((type) => (
      <button
        key={type}
        className={`flex items-center bg-white text-gray-700 p-1 rounded hover:bg-gray-100 transition-colors duration-200 ${getFeedbackStyle(
          feedbackPanel,
          messageId,
          type
        )}`}
        onClick={() => onFeedback(messageId, type)}
      >
        {type === "like" ? (
          <FaThumbsUp className="w-3 h-3 stroke-current" />
        ) : (
          <FaThumbsDown className="w-3 h-3 stroke-current" />
        )}
      </button>
    ))}
  </div>
);

const getFeedbackStyle = (feedbackPanel, messageId, type) => {
  if (
    (feedbackPanel.messageId === messageId && feedbackPanel.type === type) ||
    (messageId === feedbackPanel.messageId &&
      feedbackPanel.selectedFeedback &&
      feedbackPanel.type === type)
  ) {
    return type === "like" ? "text-green-500" : "text-red-500";
  }
  return "";
};

export default MessageActions;
