import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { useNavigate } from 'react-router-dom';
import './tabLayout.css';

const RunTabLayout = ({ children, activeIndex, setActiveIndex }) => {
    const navigate = useNavigate();

    const handleTabChange = (e) => {
        setActiveIndex(e.index);
        if (e.index === 0) {
            navigate('/portal/run');
        } else if (e.index === 1) {
            navigate('/portal/run/result');
        }
    };

    return (
        <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
            <TabPanel header="Model">{children}</TabPanel>
            <TabPanel header="Result">{children}</TabPanel>
        </TabView>
    );
};

export default RunTabLayout;
