import React, { useMemo } from "react";
import { Chart } from "primereact/chart";
import { AiOutlineBarChart } from "react-icons/ai";

const VisualizationComponent = React.memo(({ visualization, isLoading }) => {
  const renderContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="animate-pulse flex flex-col space-y-4 max-w-md mx-auto h-64">
          <div className="h-8 bg-slate-200 rounded w-1/4"></div>
          <div className="flex-1 flex items-end space-x-2">
            {[...Array(10)].map((_, index) => (
              <div
                key={index}
                className="bg-slate-200 rounded-t"
                style={{
                  width: "8%",
                  height: `${Math.random() * 80 + 20}%`,
                }}
              ></div>
            ))}
          </div>
          <div className="h-4 bg-slate-200 rounded w-full"></div>
        </div>
      );
    }

    const chartType = visualization?.chart_type;
    const chartData = visualization?.chart_data || [];

    switch (chartType) {
      case "histogram":
        return (
          <div className="bg-white shadow-md rounded-lg p-4 mb-4 max-w-md mx-auto">
            <h4 className="text-md font-semibold mb-2">Summary</h4>
            <Chart
              type="bar"
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                  y: {
                    beginAtZero: true,
                    ticks: {
                      precision: 3,
                      minTicksLimit: 5,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: true,
                    position: "top",
                  },
                  tooltip: {
                    mode: "index",
                    intersect: false,
                  },
                },
              }}
              height={300}
            />
          </div>
        );
      default:
        return (
          <div className="flex flex-col items-center justify-center h-64 bg-gray-100 rounded-lg">
            <AiOutlineBarChart className="text-gray-400 text-6xl mb-4" />
            <p className="text-gray-600 text-lg font-medium">
              No visualization available
            </p>
            <p className="text-gray-500 text-sm mt-2">
              No visualization available for this answer
            </p>
          </div>
        );
    }
  }, [visualization, isLoading]);

  return renderContent;
});

VisualizationComponent.displayName = "VisualizationComponent";

export default VisualizationComponent;
