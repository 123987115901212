import React from "react";
import { FaUser } from "react-icons/fa";

const Comment = ({ comment }) => (
  <div className="flex items-start mb-4">
    <div className="mr-3">
      {comment.user.avatar ? (
        <img
          src={comment.user.avatar}
          alt={comment.user.name}
          className="w-8 h-8 rounded-full"
        />
      ) : (
        <FaUser className="w-8 h-8 text-gray-400" />
      )}
    </div>
    <div className="flex-grow">
      <div className="flex items-center mb-1">
        <span className="font-semibold text-sm mr-2">{comment.user.name}</span>
        <span className="text-xs text-gray-500">
          {new Date(comment.createdAt).toLocaleString()}
        </span>
      </div>
      <p className="text-sm text-gray-700">{comment.text}</p>
    </div>
  </div>
);

const CommentSection = ({
  comments,
  onAddComment,
  onCommentChange,
  newComment,
}) => {
  return (
    <div className="mt-4">
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
      <div className="flex items-start">
        <div className="mr-3">
          <FaUser className="w-8 h-8 text-gray-400" />
        </div>
        <div className="flex-grow">
          <textarea
            value={newComment}
            onChange={onCommentChange}
            className="w-full p-2 mb-2 border border-gray-300 focus:ring-2 focus:ring-blue-500 rounded-md placeholder-gray-400 text-gray-700"
            rows="2"
            placeholder="Leave a comment..."
          />
          <button
            onClick={onAddComment}
            className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded text-sm"
          >
            Comment
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentSection;
