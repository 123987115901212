export const formatDate = (dateString) => {
  const date = new Date(Date.parse(dateString));
  return date.toLocaleDateString();
};

export const formatLastUpdated = (dateString) => {
  const now = new Date();
  const updated = new Date(Date.parse(dateString));
  const diffInMinutes = Math.floor((now - updated) / (1000 * 60));

  if (diffInMinutes < 1) {
    return "just now";
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}m ago`;
  } else if (diffInMinutes < 1440) {
    return `${Math.floor(diffInMinutes / 60)}h ago`;
  } else {
    return `${Math.floor(diffInMinutes / 1440)}d ago`;
  }
};
