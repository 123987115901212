import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PrimeIcons } from 'primereact/api';
import { useUser } from '../../context/userContext';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import './menubar.css';

const Menubar = () => {
    const location = useLocation();
    const { user } = useUser();

    const menuItems = [
        { path: '/portal/home', icon: 'pi pi-home', label: 'Dashboard', roles: ['BUSINESS_USER', 'USER', 'SUPER_ADMIN'] },
        { path: '/portal/data', icon: 'pi pi-database', label: 'Data', roles: ['USER', 'SUPER_ADMIN'] },
        { path: '/portal/model', icon: 'pi pi-sitemap', label: 'Train Model', roles: ['SUPER_ADMIN', 'USER'] },
        { path: '/portal/run', icon: 'pi pi-play', label: 'Run Model', roles: ['SUPER_ADMIN', 'USER'] },
        { path: '/portal/users', icon: 'pi pi-users', label: 'Users', roles: ['SUPER_ADMIN', 'ADMIN'] },
        { path: '/portal/chat', icon: 'pi pi-comments', label: 'Data Talk', roles: ['SUPER_ADMIN', 'USER'] },
        { path: '/portal/reports', icon: 'pi pi-file', label: 'Reports', roles: ['SUPER_ADMIN', 'USER'] },
    ];

    const renderMenuItem = (item) => {
        const userRoles = user?.data?.roles?.map(role => role.name.toUpperCase()) || [];
        const normalizedRequiredRoles = item.roles.map(role => role.toUpperCase());

        if (userRoles.length > 0 && normalizedRequiredRoles.some(role => userRoles.includes(role))) {
            const isActive = location.pathname === item.path;
            return (
                <li key={item.path} className={`mb-2 ${isActive ? 'bg-[#e8e8e8] rounded-md' : ''}`}>
                    <Link to={item.path} className="flex items-center px-4 py-2 text-sm text-gray-800 hover:bg-[#ebebeb] rounded-md transition-colors duration-200">
                        <i className={`${item.icon} mr-3`}></i>
                        <span>{item.label}</span>
                    </Link>
                </li>
            );
        }
        return null;
    };

    return (
        <div className="w-56 h-screen text-gray-700 flex flex-col">
            <div className="p-4">
                <img src="/asset/data/logo.png" alt="TIA Studio Logo" className="h-10 mb-4" />
            </div>
            <nav className="flex-grow p-2">
                <ul className="space-y-1">
                    {menuItems.map(renderMenuItem)}
                </ul>
            </nav>
            <div className="p-4 border-t border-gray-200">
                {user && (
                    <div className="flex items-center space-x-3 mb-4">
                        <Avatar
                            icon="pi pi-user"
                            shape="circle"
                            size="small"
                            className="h-8 w-8 flex-shrink-0"
                        />
                        <div className="overflow-hidden">
                            <p className="text-sm font-medium truncate">{user.data.name}</p>
                            <p className="text-xs text-gray-500 truncate">{user.data.email}</p>
                        </div>
                    </div>
                )}
                <Link to="/portal/logout" className="mt-4 flex items-center text-sm text-gray-700 hover:bg-[#ebebeb] hover:text-gray-900 px-4 py-2 rounded-md transition-colors duration-200">
                    <i className="pi pi-sign-out mr-2"></i>
                    <span>Sign Out</span>
                </Link>
            </div>
            <div className="p-4 text-xs text-gray-500">
                TIA Studio v1.0.0
            </div>
        </div>
    );
};
export default Menubar;
