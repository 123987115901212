const FeedbackPanel = ({ type, onClose, onFeedbackSelection }) => {
  const options =
    type === "like"
      ? ["Helpful", "Clear", "Informative", "Well-explained", "Concise"]
      : [
          "Incorrect information",
          "Unclear explanation",
          "Not relevant",
          "Poor formatting",
          "Other issue",
        ];

  return (
    <div className="absolute bottom-[-160px] right-0 w-[360px] bg-white rounded-2xl shadow-2xl border border-gray-100 p-5 transition-all duration-300 ease-out">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-base font-medium text-gray-900">
          {type === "like"
            ? "What did you like about this response?"
            : "What could be improved?"}
        </h4>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors p-2 rounded-full hover:bg-gray-50 active:bg-gray-100"
          aria-label="Close feedback panel"
        >
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="grid grid-cols-2 gap-2">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => onFeedbackSelection(option)}
            className="px-4 py-3 text-sm rounded-xl bg-gray-50 hover:bg-gray-100 active:bg-gray-200 transition-all duration-200 text-gray-900 font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FeedbackPanel;
