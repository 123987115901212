import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { UserProvider, useUser } from './context/userContext'; 
import Login from './page/login/login';
import Portal from './page/portal/portal';
import UserService from './service/UserService';
import { Toast } from 'primereact/toast';
import Forbidden from './utils/forbidden';
import SessionTimeoutPopup from './utils/session/session-timeout';
import Preview from './page/preview/preview';

const App = () => {
  const toast = useRef(null);
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [countdown, setCountdown] = useState(60); // 60 seconds countdown

  const handleSessionRefresh = () => {
    UserService.refreshSession(); // Refresh the session using the refresh token
    setShowSessionPopup(false);
    setCountdown(60); // Reset the countdown when the session is refreshed

  };

  return (
    <Router>
      <UserProvider>
        <Toast ref={toast} />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/portal/*" element={<ProtectedRoute toast={toast} setShowSessionPopup={setShowSessionPopup} setCountdown={setCountdown}><Portal /></ProtectedRoute>} />
          <Route path="/preview/*" element={<Preview />}/>
          <Route path="/forbidden" element={<Forbidden />} />
        </Routes>
        {showSessionPopup && <SessionTimeoutPopup countdown={countdown} onRefresh={handleSessionRefresh} />}
      </UserProvider>
    </Router>
  );
};

const ProtectedRoute = ({ children, toast, setShowSessionPopup, setCountdown }) => {
  const { user, logoutUser } = useUser();

  useEffect(() => {
    const checkTokenExpiration = () => {
      const timeLeft = UserService.getTokenExpirationTime() - Date.now();
      
      if (timeLeft <= 0) {
        logoutUser();
        toast.current.show({ severity: 'error', summary: 'Session expired', detail: 'Please login again.', life: 3000 });
        window.location.href = '/'; // Redirect to login page
      } else if (timeLeft <= 60000) { // Show popup 1 minute before expiration
        setShowSessionPopup(true);
        startCountdown(timeLeft);
      }
    };

    const startCountdown = (timeLeft) => {
      const countdownTime = Math.ceil(timeLeft / 1000);
      setCountdown(countdownTime);
      const countdownInterval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    };

    checkTokenExpiration();

    const interval = setInterval(checkTokenExpiration, 10000); // Check every 10 seconds

    return () => clearInterval(interval); // Clear interval on unmount
  }, [logoutUser, toast, setShowSessionPopup, setCountdown]);

  return user ? children : <Navigate to="/" replace />;
};

export default App;
