class CsvUtils {
    static parseCsv(csvString) {
        const rows = csvString.split('\n').map(row => row.trim());
        const nonEmptyRows = rows.filter(row => row.length > 0);

        if (nonEmptyRows.length === 0) {
            throw new Error('No valid rows found in the CSV data.');
        }

        // Function to parse a single CSV row with quoted fields
        const parseRow = (row) => {
            const pattern = /(".*?"|[^,]+)(?=\s*,|\s*$)/g;
            return row.match(pattern).map(field => field.replace(/^"|"$/g, ''));
        };

        const headers = parseRow(nonEmptyRows[0]);
        const data = nonEmptyRows.slice(1).map(row => {
            const rowData = {};
            const values = parseRow(row);
            for (let i = 0; i < values.length; i++) {
                const header = headers[i] || `Column_${i + 1}`;
                rowData[header] = values[i].trim();
            }
            return rowData;
        });

        return { headers, data };
    }
}

export default CsvUtils;
