import React, { useEffect, useState, useCallback, memo } from "react";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "lodash";
import { formatDate, formatLastUpdated } from "../util/dateUtils";
import QuestionItem from "./question-item";
import { useDropzone } from "react-dropzone";
import {
  AiOutlineFilePdf,
  AiOutlineUpload,
  AiOutlineExport,
  AiOutlinePlus,
} from "react-icons/ai";
import S3Service from "../../service/S3service";
import ReportService from "../../service/ReportService";

const ReportDetails = ({ reports, reportId, onShowToast }) => {
  const [report, setReport] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showPreviewButton, setShowPreviewButton] = useState(false);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState("template1");
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [showLogoUpload, setShowLogoUpload] = useState(false);

  useEffect(() => {
    const fetchReport = async () => {
      setIsLoading(true);
      try {
        const reportData = await ReportService.getReportById(reportId);
        setReport(reportData);
      } catch (error) {
        console.error("Error fetching report:", error);
        onShowToast("error", "Error", "Failed to fetch report");
      } finally {
        setIsLoading(false);
      }
    };
    if (reportId) {
      fetchReport();
    }
  }, [reportId, onShowToast]);

  const handlePreview = useCallback(() => {
    const previewUrl = `${window.location.origin}/preview/report/${reportId}`;
    window.open(previewUrl, "_blank");
  }, [reportId]);

  const debouncedUpdateReport = useCallback(
    debounce(async (updatedReport) => {
      try {
        await ReportService.updateReport(
          updatedReport.report_id,
          updatedReport
        );
        setReport(updatedReport);
      } catch (error) {
        console.error("Error updating report:", error);
        onShowToast("error", "Error", "Failed to update report");
      }
    }, 500),
    [onShowToast]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateReport.cancel();
    };
  }, [debouncedUpdateReport]);

  const handleLogoUpload = useCallback(
    async (file) => {
      if (!file || !report) return;

      setIsUploadingLogo(true);
      try {
        const fileName = `logos/${report.report_id}/${file.name}`;
        const response = await S3Service.uploadFile(fileName, file);

        if (response.status === "OK") {
          const updatedReport = {
            ...report,
            logo: response.data.fileUrl,
            updatedAt: new Date().toISOString(),
          };
          setReport(updatedReport);
          debouncedUpdateReport(updatedReport);
        }
      } catch (error) {
        console.error("Error uploading logo:", error);
        onShowToast("error", "Upload Failed", "Failed to upload logo");
      } finally {
        setIsUploadingLogo(false);
      }
    },
    [report, debouncedUpdateReport, onShowToast]
  );

  const handleTitleChange = useCallback(
    (newTitle) => {
      if (!report) return;
      const updatedReport = {
        ...report,
        title: newTitle,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);
    },
    [report, debouncedUpdateReport]
  );
  const handleConfigureTemplate = useCallback(() => {
    setIsTemplateModalOpen(true);
  }, []);
  const handleTemplateSelect = useCallback(
    (templateId) => {
      setSelectedTemplateId(templateId);
      console.log(`Selected template: ${templateId}`);
      setIsTemplateModalOpen(false);

      if (!report) return;
      const updatedReport = {
        ...report,
        template_id: templateId,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);
      debouncedUpdateReport(updatedReport);

      onShowToast(
        "success",
        "Template Updated",
        "Report template has been updated"
      );
    },
    [onShowToast, report, debouncedUpdateReport]
  );
  const handleMarkAsCompleted = useCallback(() => {
    if (!report) return;

    const previousReport = report;
    const updatedReport = {
      ...report,
      completed: true,
      updatedAt: new Date().toISOString(),
    };
    setReport(updatedReport);
    try {
      debouncedUpdateReport(updatedReport);
      onShowToast(
        "success",
        "Report Completed",
        "Report has been marked as completed"
      );
    } catch (error) {
      setReport(previousReport);
      onShowToast(
        "error",
        "Update Failed",
        "Failed to mark report as completed"
      );
    }
  }, [report, debouncedUpdateReport, onShowToast]);

  const handleExport = useCallback(() => {
    onShowToast("info", "Export", "Export functionality not implemented yet");
  }, [onShowToast]);

  const handleAddQuestion = useCallback(() => {
    if (!report) return;
    const newQuestion = {
      question: "",
      answer: "",
      comment: "",
      status: "draft",
      visualization: null,
    };
    const updatedReport = {
      ...report,
      questions: [...report.questions, newQuestion],
      updatedAt: new Date().toISOString(),
    };
    setReport(updatedReport);
  }, [report]);

  const handleDeleteQuestion = useCallback(
    async (questionId) => {
      if (!report) return;

      const updatedQuestions = report.questions.filter(
        (q) => q.question_id !== questionId
      );
      const updatedReport = {
        ...report,
        questions: updatedQuestions,
        updatedAt: new Date().toISOString(),
      };
      setReport(updatedReport);

      try {
        if (questionId) {
          await ReportService.deleteQuestion(report.report_id, questionId);
        }
      } catch (error) {
        console.error("Failed to delete question:", error);
        setReport(report);
        onShowToast(
          "error",
          "Delete Failed",
          "Failed to delete question. Please try again."
        );
      }
    },
    [report, onShowToast]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setIsUploading(true);
        try {
          // Upload to S3
          const uploadedFileURL = await uploadFileToS3(file);

          // Fetch generated questions
          const generatedQuestions = await fetchGeneratedQuestions(
            uploadedFileURL
          );

          // Map the generated questions to your question format
          const newQuestions = generatedQuestions.map((q) => ({
            id: uuidv4(),
            question: q,
            answer: "",
            comment: "",
            status: "draft",
          }));

          // Update the report with new questions
          const updatedReport = {
            ...report,
            questions: [...report.questions, ...newQuestions],
            updatedAt: new Date().toISOString(),
          };

          debouncedUpdateReport(updatedReport);

          onShowToast(
            "success",
            "Questions Generated",
            "Questions have been added to the report"
          );
        } catch (error) {
          onShowToast(
            "error",
            "Upload Failed",
            "There was an error processing your file. Please try again."
          );
        } finally {
          setIsUploading(false);
        }
      }
    },
    [report, debouncedUpdateReport, onShowToast]
  );

  const uploadFileToS3 = async (file) => {
    try {
      const fileName = file.name;
      const response = await S3Service.uploadFile(fileName, file);

      if (response.status === "OK") {
        return response.data.fileUrl; // Assuming the response includes the file URL
      } else {
        throw new Error(response.message || "Error uploading file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  // Mock fetch function
  const fetchGeneratedQuestions = async (fileURL) => {
    // Simulate a delay for fetching questions
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          "What are the key findings from the document?",
          "Can you elaborate on the main challenges discussed?",
          "What recommendations are provided in the document?",
        ]);
      }, 1000);
    });
  };

  // Initialize react-dropzone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [] },
    multiple: false,
    disabled: isUploading,
  });

  if (!reportId) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">
          Select a report or create a new one to get started.
        </p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="col-span-3 w-full p-6 bg-white overflow-y-scroll h-full mx-auto">
        <div className="mb-8 animate-pulse">
          <div className="h-12 w-3/4 bg-gray-200 rounded mb-2"></div>
          <div className="flex items-center text-sm text-gray-500">
            <div className="h-4 w-32 bg-gray-200 rounded mr-2"></div>
            <span>•</span>
            <div className="h-4 w-40 bg-gray-200 rounded ml-2"></div>
          </div>
        </div>
        {[1, 2, 3].map((i) => (
          <div key={i} className="mb-6 animate-pulse">
            <div className="h-24 bg-gray-200 rounded mb-4"></div>
            <div className="h-16 bg-gray-100 rounded"></div>
          </div>
        ))}
        <div className="fixed bottom-6 right-6 flex space-x-2">
          <div className="h-10 w-32 bg-gray-200 rounded animate-pulse"></div>
          <div className="h-10 w-24 bg-gray-200 rounded animate-pulse"></div>
        </div>
      </div>
    );
  }

  if (!report) {
    return (
      <div className="col-span-3 p-4 bg-white flex items-center justify-center h-full">
        <p className="text-gray-500">Report not found or failed to load.</p>
      </div>
    );
  }

  return (
    <>
      <div className="col-span-3 w-full p-6 bg-white overflow-y-scroll h-full mx-auto">
        <div className="mb-8">
          {report.template_id === "template1" ? (
            // Default vertical layout
            <>
              <div
                className="relative mb-4 group"
                onMouseEnter={() => setShowLogoUpload(true)}
                onMouseLeave={() => setShowLogoUpload(false)}
              >
                {report.logo ? (
                  <img
                    src={report.logo}
                    alt="Report Logo"
                    className="h-16 w-16 object-contain cursor-pointer hover:opacity-75 transition-opacity"
                    onClick={() =>
                      document.getElementById("logo-upload").click()
                    }
                  />
                ) : (
                  <div
                    className="h-16 w-16 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
                    onClick={() =>
                      document.getElementById("logo-upload").click()
                    }
                  >
                    <AiOutlinePlus className="text-gray-400 text-xl" />
                  </div>
                )}

                <input
                  type="file"
                  id="logo-upload"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleLogoUpload(e.target.files[0])}
                  disabled={isUploadingLogo}
                />
                {showLogoUpload && (
                  <div className="absolute left-0 -bottom-6 text-xs text-gray-500">
                    {isUploadingLogo ? "Uploading..." : "Click to add logo"}
                  </div>
                )}
              </div>
              <div
                className="relative"
                onMouseEnter={() => setShowPreviewButton(true)}
                onMouseLeave={() => setShowPreviewButton(false)}
              >
                <input
                  type="text"
                  value={report.title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  className="font-bold text-4xl bg-transparent border-none focus:outline-none w-full mb-2"
                  placeholder="Untitled"
                />
                {showPreviewButton && (
                  <button
                    onClick={handlePreview}
                    className="absolute bg-transparent right-0 top-1/2 -translate-y-1/2 p-2 text-gray-500 hover:text-blue-500 flex items-center"
                    title="Preview report in new tab"
                  >
                    <span className="mr-1">Preview</span>
                    <AiOutlineExport size={20} />
                  </button>
                )}
              </div>
              <div className="flex items-center text-sm text-gray-500">
                <span className="mr-2">
                  Created: {formatDate(report.created_at)}
                </span>
                <span>•</span>
                <span className="ml-2">
                  Last updated: {formatLastUpdated(report.updated_at)}
                </span>
              </div>
            </>
          ) : report.template_id === "template2" ? (
            // Horizontal layout
            <div className="flex items-center border-b border-[#d1d1d1] border-0 border-solid pb-6 justify-between">
              <div className="flex items-center gap-6">
                <div
                  className="relative group"
                  onMouseEnter={() => setShowLogoUpload(true)}
                  onMouseLeave={() => setShowLogoUpload(false)}
                >
                  {report.logo ? (
                    <img
                      src={report.logo}
                      alt="Report Logo"
                      className="h-16 w-16 object-contain cursor-pointer hover:opacity-75 transition-opacity"
                      onClick={() =>
                        document.getElementById("logo-upload").click()
                      }
                    />
                  ) : (
                    <div
                      className="h-16 w-16 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
                      onClick={() =>
                        document.getElementById("logo-upload").click()
                      }
                    >
                      <AiOutlinePlus className="text-gray-400 text-xl" />
                    </div>
                  )}
                  <input
                    type="file"
                    id="logo-upload"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => handleLogoUpload(e.target.files[0])}
                    disabled={isUploadingLogo}
                  />
                  {showLogoUpload && (
                    <div className="absolute left-0 -bottom-6 text-xs text-gray-500">
                      {isUploadingLogo ? "Uploading..." : "Click to add logo"}
                    </div>
                  )}
                </div>
                <div
                  className="relative"
                  onMouseEnter={() => setShowPreviewButton(true)}
                  onMouseLeave={() => setShowPreviewButton(false)}
                >
                  <input
                    type="text"
                    value={report.title}
                    onChange={(e) => handleTitleChange(e.target.value)}
                    className="font-serif text-5xl bg-transparent border-none focus:outline-none"
                    placeholder="Untitled"
                  />
                  {showPreviewButton && (
                    <button
                      onClick={handlePreview}
                      className="absolute bg-transparent right-0 top-1/2 -translate-y-1/2 p-2 text-gray-500 hover:text-blue-500 flex items-center"
                      title="Preview report in new tab"
                    >
                      <span className="mr-1">Preview</span>
                      <AiOutlineExport size={20} />
                    </button>
                  )}
                </div>
              </div>
              <div className="text-sm text-gray-500">
                <div>Created: {formatDate(report.created_at)}</div>
                <div>Last updated: {formatLastUpdated(report.updated_at)}</div>
              </div>
            </div>
          ) : report.template_id === "template3" ? (
            // Minimal layout with monospace font
            <div className="space-y-4">
              <div
                className="relative group"
                onMouseEnter={() => setShowLogoUpload(true)}
                onMouseLeave={() => setShowLogoUpload(false)}
              >
                {report.logo ? (
                  <img
                    src={report.logo}
                    alt="Report Logo"
                    className="h-12 w-12 object-contain cursor-pointer hover:opacity-75 transition-opacity"
                    onClick={() =>
                      document.getElementById("logo-upload").click()
                    }
                  />
                ) : (
                  <div
                    className="h-12 w-12 border border-gray-300 rounded flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
                    onClick={() =>
                      document.getElementById("logo-upload").click()
                    }
                  >
                    <AiOutlinePlus className="text-gray-400 text-xl" />
                  </div>
                )}
                <input
                  type="file"
                  id="logo-upload"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleLogoUpload(e.target.files[0])}
                  disabled={isUploadingLogo}
                />
              </div>
              <div
                className="relative"
                onMouseEnter={() => setShowPreviewButton(true)}
                onMouseLeave={() => setShowPreviewButton(false)}
              >
                <input
                  type="text"
                  value={report.title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  className="font-mono text-4xl bg-transparent border-none focus:outline-none w-full"
                  placeholder="Untitled"
                />
                {showPreviewButton && (
                  <button
                    onClick={handlePreview}
                    className="absolute bg-transparent right-0 top-1/2 -translate-y-1/2 p-2 text-gray-500 hover:text-blue-500 flex items-center"
                    title="Preview report in new tab"
                  >
                    <span className="mr-1">Preview</span>
                    <AiOutlineExport size={20} />
                  </button>
                )}
              </div>
              <div className="font-mono text-xs text-gray-500">
                {formatDate(report.created_at)} • Updated{" "}
                {formatLastUpdated(report.updated_at)}
              </div>
            </div>
          ) : (
            // Default template
            <div className="space-y-4">
              <div
                className="relative group"
                onMouseEnter={() => setShowLogoUpload(true)}
                onMouseLeave={() => setShowLogoUpload(false)}
              >
                {report.logo ? (
                  <img
                    src={report.logo}
                    alt="Report Logo"
                    className="h-16 w-16 object-contain cursor-pointer hover:opacity-75 transition-opacity"
                    onClick={() =>
                      document.getElementById("logo-upload").click()
                    }
                  />
                ) : (
                  <div
                    className="h-16 w-16 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400 transition-colors"
                    onClick={() =>
                      document.getElementById("logo-upload").click()
                    }
                  >
                    <AiOutlinePlus className="text-gray-400 text-xl" />
                  </div>
                )}
                <input
                  type="file"
                  id="logo-upload"
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => handleLogoUpload(e.target.files[0])}
                  disabled={isUploadingLogo}
                />
              </div>
              <div
                className="relative"
                onMouseEnter={() => setShowPreviewButton(true)}
                onMouseLeave={() => setShowPreviewButton(false)}
              >
                <input
                  type="text"
                  value={report.title}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  className="text-4xl font-bold bg-transparent border-none focus:outline-none w-full"
                  placeholder="Untitled"
                />
                {showPreviewButton && (
                  <button
                    onClick={handlePreview}
                    className="absolute bg-transparent right-0 top-1/2 -translate-y-1/2 p-2 text-gray-500 hover:text-blue-500 flex items-center"
                    title="Preview report in new tab"
                  >
                    <span className="mr-1">Preview</span>
                    <AiOutlineExport size={20} />
                  </button>
                )}
              </div>
              <div className="text-sm text-gray-500">
                Created: {formatDate(report.created_at)} • Last updated:{" "}
                {formatLastUpdated(report.updated_at)}
              </div>
            </div>
          )}
        </div>
        {report.questions.map((question) => (
          <QuestionItem
            key={question.question_id}
            question={question}
            report={report}
            reports={reports}
            setReport={setReport}
            onDeleteQuestion={() => handleDeleteQuestion(question.question_id)}
          />
        ))}
        {report.questions.length === 0 && (
          <>
            <p className="text-lg font-medium text-gray-700 mb-4">
              Get started by uploading a PDF
            </p>
            <div
              className={`mb-4 p-4 border-2 border-dashed rounded-md transition-colors duration-200 ${
                isDragActive
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-300 hover:border-blue-500"
              } ${
                isUploading ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              <div {...getRootProps()} className="text-center py-10">
                <input {...getInputProps()} />
                {isUploading ? (
                  <div className="space-y-3">
                    <div className="h-12 w-12 mx-auto bg-gray-200 rounded-full animate-pulse"></div>
                    <div className="h-4 w-3/4 mx-auto bg-gray-200 rounded animate-pulse"></div>
                    <div className="h-4 w-1/2 mx-auto bg-gray-200 rounded animate-pulse"></div>
                  </div>
                ) : (
                  <>
                    <AiOutlineFilePdf className="mx-auto h-12 w-12 text-gray-400 group-hover:text-blue-500 transition-colors duration-200" />
                    <p className="mt-2 text-gray-600 text-sm font-medium group-hover:text-blue-600 transition-colors duration-200">
                      {isDragActive
                        ? "Drop the PDF file here"
                        : "Drag and drop a PDF file here, or click to select a file"}
                    </p>
                    <p className="mt-1 text-gray-500 text-xs group-hover:text-blue-500 transition-colors duration-200">
                      Supported file type: PDF
                    </p>
                    <button className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                      <AiOutlineUpload className="mr-2" />
                      Select PDF
                    </button>
                  </>
                )}
              </div>
            </div>
            <p className="text-lg font-medium text-gray-700 mb-4">
              Or, start from scratch by adding a question
            </p>
          </>
        )}
        <div>
          <button
            className="flex w-full text-left p-2 bg-transparent hover:bg-gray-100 rounded transition-colors duration-200 text-gray-600 hover:text-gray-800"
            onClick={handleAddQuestion}
          >
            + Add a question
          </button>
        </div>

        <div className="fixed bottom-6 right-6 flex space-x-2">
          <button
            onClick={handleMarkAsCompleted}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={report.completed || isUploading}
          >
            {report.completed ? "Completed" : "Mark as completed"}
          </button>
          <button
            onClick={handleExport}
            className="bg-gray-200 text-gray-700 px-4 py-2 rounded hover:bg-gray-300 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isUploading}
          >
            Export
          </button>
          <button
            onClick={handleConfigureTemplate}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors duration-200 shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={isUploading}
          >
            Configure Template
          </button>
        </div>
      </div>
      {isTemplateModalOpen && (
        <div className="fixed inset-0 bg-black/30 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white/90 backdrop-blur-xl p-8 rounded-2xl w-[800px] max-h-[80vh] overflow-y-auto shadow-2xl">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-semibold text-gray-900">
                Choose a Template
              </h2>
              <button
                onClick={() => setIsTemplateModalOpen(false)}
                className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors duration-200"
                aria-label="Close modal"
              >
                <svg
                  className="w-5 h-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="grid grid-cols-3 gap-6">
              {[
                {
                  id: "template1",
                  name: "Basic Report",
                  description: "Clean and simple template for standard reports",
                  icon: "📄",
                },
                {
                  id: "template2",
                  name: "Technical Analysis",
                  description:
                    "Comprehensive template with data visualization sections",
                  icon: "📊",
                },
                {
                  id: "template3",
                  name: "Executive Summary",
                  description: "Focused template optimized for key insights",
                  icon: "📋",
                },
              ].map((template) => (
                <button
                  key={template.id}
                  onClick={() => handleTemplateSelect(template.id)}
                  className={`group p-6 rounded-xl transition-all duration-300 text-left
                    ${
                      template.id === selectedTemplateId
                        ? "bg-blue-50/80 ring-2 ring-blue-500 shadow-lg scale-[1.02]"
                        : "hover:bg-gray-50/80 hover:ring-2 bg-transparent hover:ring-gray-200 hover:scale-[1.02]"
                    }`}
                >
                  <div className="text-4xl mb-4 group-hover:scale-110 transition-transform duration-300">
                    {template.icon}
                  </div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">
                    {template.name}
                  </h3>
                  <p className="text-sm text-gray-500 leading-relaxed">
                    {template.description}
                  </p>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(
  ReportDetails,
  (prevProps, nextProps) => prevProps.reportId === nextProps.reportId
);
