import React from "react";

const References = ({ references }) => {
  return (
    <div className="relative group flex items-center">
      <button className="text-blue-600 text-xs bg-transparent hover:underline focus:outline-none mr-4">
        References ({references ? references.length : 0})
      </button>
      {references && references.length > 0 && (
        <div className="hidden group-hover:block group-focus-within:block absolute bg-white border border-gray-300 p-2 shadow-md z-10 top-full left-0 w-max max-w-xs whitespace-normal">
          {references.map((ref, refIndex) => (
            <div key={refIndex} className="mb-1">
              <a
                href={ref}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 text-xs no-underline hover:underline"
              >
                {ref}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default References;
