import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import GraphLayout from '../graphLayout';
import Papa from 'papaparse';

const ScatterPlot = ({ selectedResponse, summaryData, csvData }) => {
    const [data, setData] = useState({ labels: [], datasets: [] });
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        if (csvData.length > 0) {
            processCsvData(csvData);
        }
    }, [csvData]);

    const getChartOptions = () => {
        return {
            maintainAspectRatio: false,
            aspectRatio: 1.5,
            plugins: {
                legend: {
                    position: 'top',
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    title: {
                        display: true,
                        text: 'Time',
                    },
                },
                y: {
                    grid: {
                        display: true,
                    },
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: 'Utilization',
                    },
                },
            },
        };
    };

    const processCsvData = (data) => {
        const labels = data.map(row => row.Date);
        const values = data.map(row => row.utilization);

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Utilization',
                    data: values,
                    fill: false,
                    borderDash: [5, 5],
                    tension: 0.4,
                    borderColor: '#66BB6A',
                }
            ]
        };

        setData(chartData);
        setChartOptions(getChartOptions());
    };

    // const labels = ['MAE', 'RMSE', 'R Square', 'MAE Control Range'];
    // const values = selectedResponse
    //     ? [selectedResponse.mae, selectedResponse.rmse, selectedResponse.r_square, selectedResponse.mae_control_range]
    //     : [0, 0, 0, 0];

    // const data = {
    //     labels: labels,
    //     datasets: [
    //         {
    //             label: 'Scatter Plot Data Points',
    //             data: [
    //                 { x: 'MAE', y: values[0] },
    //                 { x: 'RMSE', y: values[1] },
    //                 { x: 'R Square', y: values[2] },
    //                 { x: 'MAE Control Range', y: values[3] },
    //             ],
    //             backgroundColor: 'rgba(255, 99, 132, 0.2)',
    //             borderColor: 'rgba(255, 99, 132, 1)',
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    // const options = {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     scales: {
    //         x: {
    //             type: 'category',
    //             title: {
    //                 display: true,
    //                 text: 'Metrics',
    //             },
    //         },
    //         y: {
    //             beginAtZero: true,
    //             title: {
    //                 display: true,
    //                 text: 'Values',
    //             },
    //         },
    //     },
    // };

    return (
        <GraphLayout
            title="Utilization"
            chartComponent={<Chart type="line" data={data} options={chartOptions} />}
            selectedResponse={selectedResponse}
            summaryData={summaryData}
        />
    );
};

export default ScatterPlot;
