import axios from 'axios';
import { MODEL_API_BASE_URL } from '../utils/config';
import { PYTHON_API_BASE_URL } from '../utils/config';

const Json_MODEL_API_BASE_URL = `${MODEL_API_BASE_URL}/json`;

const ModelService = {
    getAllJsonData: async (page, size) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(Json_MODEL_API_BASE_URL, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                params: {
                    page: page,
                    size: size
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },


    getJsonDataById: async (id) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${Json_MODEL_API_BASE_URL}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    addJsonData: async (jsonData) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(Json_MODEL_API_BASE_URL, jsonData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    updateJsonData: async (id, jsonData) => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.put(`${Json_MODEL_API_BASE_URL}/${id}`, jsonData, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    deleteJsonData: async (id) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${Json_MODEL_API_BASE_URL}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
        } catch (error) {
            throw error;
        }
    },

    getAllModelName: async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(Json_MODEL_API_BASE_URL + "/model-names", {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    dynamicAPI: async (jsonData) => {
        try {
            const response = await axios.post(PYTHON_API_BASE_URL, jsonData);
            return response.data;
        } catch (error) {
            throw error;
        }
    },
};

export default ModelService;
