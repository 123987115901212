import React, { useState, useEffect, forwardRef, useImperativeHandle,useRef } from 'react';
import { Chart } from 'primereact/chart';
import { InputNumber } from 'primereact/inputnumber';
import GraphLayout from '../graphLayout';
import Papa from 'papaparse';
import 'chartjs-adapter-date-fns';
import './prediction.css';

const Prediction = forwardRef(({ selectedResponse, summaryData, csvData }, ref) => {
    const [data, setData] = useState({ labels: [], datasets: [] });
    const [truePredData, setTruePredData] = useState({ labels: [], datasets: [] });
    const [xStep, setXStep] = useState(1);
    const [yStep, setYStep] = useState(1);
    const [xAxisStart, setXAxisStart] = useState(27);
    const [yAxisStart, setYAxisStart] = useState(27);
    const [showGraph, setShowGraph] = useState(false);
    const layoutRef = useRef(null);

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'Prediction',
                },
                ticks: {
                    stepSize: yStep,
                    precision: 0,
                    min: 0,
                    minTicksLimit: 8,
                    suggestedMin: yAxisStart,
                    autoSkip: false,
                },
                min: yAxisStart,
            },
            x: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'True',
                },
                ticks: {
                    stepSize: xStep,
                    precision: 0,
                    suggestedMin: xAxisStart,
                    autoSkip: false,
                },
                min: xAxisStart,
            },
        },
    });

    const [truePredOptions, setTruePredOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'Time',
                },
                ticks: {
                    autoSkip: true,
                },
            },
            y: {
                beginAtZero: false,
                title: {
                    display: true,
                    text: 'True vs Prediction',
                },
            },
        },
    });

    useImperativeHandle(ref, () => ({
        captureScreenshot: () => {
            return layoutRef.current;
        }
    }));

    useEffect(() => {
        if (csvData.length > 0) {
            processCsvData(csvData);
            processTrueCsvData(csvData)
        }
    }, [csvData]);

    useEffect(() => {
        setOptions(prevOptions => {
            const updatedOptions = {
                ...prevOptions,
                scales: {
                    ...prevOptions.scales,
                    y: {
                        ...prevOptions.scales.y,
                        beginAtZero: yAxisStart === 0,
                        ticks: {
                            ...prevOptions.scales.y.ticks,
                            stepSize: yStep,
                            autoSkip: false,
                        },
                        min: yAxisStart,
                    },
                    x: {
                        ...prevOptions.scales.x,
                        beginAtZero: xAxisStart === 0,
                        ticks: {
                            ...prevOptions.scales.x.ticks,
                            stepSize: xStep,
                            precision: 0,
                            autoSkip: false,
                        },
                        min: xAxisStart,
                    },
                },
            };
            return updatedOptions;
        });
    }, [xStep, yStep, xAxisStart, yAxisStart]);

    const processCsvData = (data) => {
        const labels = data.map(row => row.TRUE);
        const values = data.map(row => ({
            x: row.TRUE,
            y: row.Prediction,
        }));
        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Prediction Data Points',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    pointBackgroundColor: 'rgba(75, 192, 192, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    data: values,
                },
            ],
        };

        setData(chartData);
    };

    const processTrueCsvData = (data) => {
        const labels = data.map(row => row.Time);
        const trueValues = data.map(row => ({
            x: row.Time,
            y: row.TRUE,
        }));
        const predictionValues = data.map(row => ({
            x: row.Time,
            y: row.Prediction,
        }));

        let mean = 28.85801;
        let UL = 29.77147;
        let LL = 27.94455;

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'True Values',
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    pointBackgroundColor: 'rgba(255, 99, 132, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                    pointStyle: 'triangle',
                    pointRadius: 5,
                    showLine: false,
                    data: trueValues,
                },
                {
                    label: 'Prediction Values',
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    pointBackgroundColor: 'rgba(54, 162, 235, 1)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    pointStyle: 'circle',
                    pointRadius: 5,
                    showLine: false,
                    data: predictionValues,
                },
                // Mean line
                {
                    label: 'Mean',
                    backgroundColor: 'transparent',
                    borderColor: 'green',
                    borderWidth: 2,
                    borderDash: [10, 5], // Dashed line
                    fill: false,
                    data: [{ x: labels[0], y: mean }, { x: labels[labels.length - 2], y: mean }],
                },
                // Upper Limit line
                {
                    label: 'UL',
                    backgroundColor: 'transparent',
                    borderColor: 'red',
                    borderWidth: 2,
                    borderDash: [10, 5], // Dashed line
                    fill: false,
                    data: [{ x: labels[0], y: UL }, { x: labels[labels.length - 2], y: UL }],
                },
                // Lower Limit line
                {
                    label: 'LL',
                    backgroundColor: 'transparent',
                    borderColor: 'blue',
                    borderWidth: 2,
                    borderDash: [10, 5], // Dashed line
                    fill: false,
                    data: [{ x: labels[0], y: LL }, { x: labels[labels.length - 2], y: LL }],
                },
            ],
        };
        setTruePredData(chartData);
    };

    return (
        <GraphLayout ref={layoutRef}
            title={
                <>
                    <div className='headertitle'>
                        <h3>Prediction</h3>
                        <button onClick={() => setShowGraph(!showGraph)} className="toggle-graph-button">
                            {showGraph ? 'Hide Scatter' : 'Show Scatter'}
                        </button>
                    </div>
                </>
            }
            chartComponent={
                <>
                    {showGraph && (
                        <div className='prediction-container'>
                            <Chart type="scatter" data={data} options={options} className='chart' />
                            <div className="p-grid p-formgrid p-fluid input-container">
                                <div className="p-field">
                                    <label htmlFor="xAxisStart">X-Axis Start</label>
                                    <InputNumber
                                        id="xAxisStart"
                                        value={xAxisStart}
                                        onValueChange={(e) => setXAxisStart(e.value)}
                                        placeholder="Set X-Axis Start"
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="yAxisStart">Y-Axis Start</label>
                                    <InputNumber
                                        id="yAxisStart"
                                        value={yAxisStart}
                                        onValueChange={(e) => setYAxisStart(e.value)}
                                        placeholder="Set Y-Axis Start"
                                    />
                                </div>
                            </div>
                            <div className="p-grid p-formgrid p-fluid input-container">
                                <div className="p-field">
                                    <label htmlFor="xStep">X-Axis Step Size</label>
                                    <InputNumber
                                        id="xStep"
                                        value={xStep}
                                        onValueChange={(e) => setXStep(e.value)}
                                        placeholder="Set X-Axis Step Size"
                                    />
                                </div>
                                <div className="p-field">
                                    <label htmlFor="yStep">Y-Axis Step Size</label>
                                    <InputNumber
                                        id="yStep"
                                        value={yStep}
                                        onValueChange={(e) => setYStep(e.value)}
                                        placeholder="Set Y-Axis Step Size"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {!showGraph && (
                        <div>
                            <Chart type="line" data={truePredData} options={truePredOptions} className='chart' />
                        </div>
                    )}
                </>
            }
            selectedResponse={selectedResponse}
            summaryData={summaryData}
        />
    );
});

export default Prediction;
