import React from 'react';
import * as XLSX from 'xlsx';
import { Button } from 'primereact/button';
import { useLocation } from 'react-router-dom';
import { Chart } from 'primereact/chart';
import jsPDF from 'jspdf';
import './report.css';

const Report = () => {
    const location = useLocation();
    const { predictionData, healthData, utilizationData, screenshotData } = location.state || {};

    const generateExcelFile = () => {
        const workbook = XLSX.utils.book_new();

        const predictionSheet = XLSX.utils.json_to_sheet(predictionData);
        XLSX.utils.book_append_sheet(workbook, predictionSheet, 'Prediction Data');

        const healthSheet = XLSX.utils.json_to_sheet(healthData);
        XLSX.utils.book_append_sheet(workbook, healthSheet, 'Health Data');

        const utilizationSheet = XLSX.utils.json_to_sheet(utilizationData);
        XLSX.utils.book_append_sheet(workbook, utilizationSheet, 'Utilization Data');

        XLSX.writeFile(workbook, 'Report.xlsx');
    };

    const generatePDF = () => {
        const doc = new jsPDF('p', 'mm', 'a4');
        const imgProps = doc.getImageProperties(screenshotData);
        const pdfWidth = doc.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        doc.addImage(screenshotData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        doc.save('report.pdf');
    };

    const chartData = {
        labels: ['Prediction Data', 'Health Data', 'Utilization Data'],
        datasets: [
            {
                label: 'Number of Entries',
                backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
                data: [predictionData.length, healthData.length, utilizationData.length]
            }
        ]
    };

    const pieChartData = {
        labels: ['Prediction', 'Health', 'Utilization'],
        datasets: [
            {
                data: [predictionData.length, healthData.length, utilizationData.length],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }
        ]
    };

    const lineChartData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
            {
                label: 'Prediction Trend',
                data: [15, 30, 45, 60],
                fill: false,
                borderColor: '#42A5F5',
                tension: 0.4
            },
            {
                label: 'Health Trend',
                data: [10, 20, 35, 50],
                fill: false,
                borderColor: '#66BB6A',
                tension: 0.4
            },
            {
                label: 'Utilization Trend',
                data: [20, 25, 40, 55],
                fill: false,
                borderColor: '#FFA726',
                tension: 0.4
            }
        ]
    };

    const polarChartData = {
        labels: ['Prediction', 'Health', 'Utilization'],
        datasets: [
            {
                data: [predictionData.length, healthData.length, utilizationData.length],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }
        ]
    };

    return (
        <div className="report-container">
            <div className="report-header">
                <h2>Data Report</h2>
                <p>Comprehensive analysis of collected data.</p>
            </div>
            <div className="report-buttons">
                <Button
                    label="Download Excel"
                    icon="pi pi-file-excel"
                    className="p-button-success"
                    onClick={generateExcelFile}
                />
                <Button
                    label="Download PDF"
                    icon="pi pi-file-pdf"
                    className="p-button-danger"
                    onClick={generatePDF}
                />
            </div>
            <div className="statistics-section">
                <h3>Summary Statistics</h3>
                <div className="statistics-cards">
                    <div className="stat-card">
                        <h4>Total Predictions</h4>
                        <p>{predictionData.length}</p>
                    </div>
                    <div className="stat-card">
                        <h4>Total Health Checks</h4>
                        <p>{healthData.length}</p>
                    </div>
                    <div className="stat-card">
                        <h4>Total Utilizations</h4>
                        <p>{utilizationData.length}</p>
                    </div>
                </div>
            </div>
            <div className="chart-section">
                <h3>Data Visualizations</h3>
                <div className="chart-wrapper">
                    <Chart type="bar" data={chartData} options={{ maintainAspectRatio: false }} />
                    <Chart type="doughnut" data={pieChartData} options={{ maintainAspectRatio: false }} />
                    <Chart type="line" data={lineChartData} options={{ maintainAspectRatio: false }} />
                    <Chart type="polarArea" data={polarChartData} options={{ maintainAspectRatio: false }} />
                </div>
            </div>
        </div>
    );
};

export default Report;
