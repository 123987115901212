import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const formatCountdown = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

const SessionTimeoutPopup = ({ initialCountdown, onRefresh }) => {
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);

    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <Dialog header="Session Timeout Warning" visible={true} onHide={() => {}}>
      <div className="session-timeout-popup">
        <p>Your session will expire in <strong>{formatCountdown(countdown)}</strong>.</p>
        <Button label="Refresh Session" onClick={onRefresh} />
      </div>
    </Dialog>
  );
};

export default SessionTimeoutPopup;
